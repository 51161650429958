@import url("https://fonts.googleapis.com/css?family=Rajdhani:400,600,700");
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700");

body {
    color: #797979;
    font-family: "Roboto", sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.667
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0
}

a:hover {
    text-decoration: none
}

button {
    cursor: pointer
}

button:focus {
    outline: 0;
    box-shadow: none
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Rajdhani", sans-serif;
    color: #3a414e;
    line-height: 1.25
}

h1, .h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 1.2
}

@media (min-width: 600px) {
    h1, .h1 {
        font-size: 50px
    }
}

h2, .h2 {
    font-size: 28px;
    font-weight: 700
}

@media (min-width: 1000px) {
    h2, .h2 {
        font-size: 36px
    }
}

h3, .h3 {
    font-size: 20px;
    font-weight: 600
}

@media (min-width: 1000px) {
    h3, .h3 {
        font-size: 22px
    }
}

.pb-85px {
    padding-bottom: 45px
}

@media (min-width: 1000px) {
    .pb-85px {
        padding-bottom: 85px
    }
}

.section-margin {
    padding: 60px 0;
    background-color: #ffffff
}

@media (min-width: 1000px) {
    .section-margin {
        padding: 100px 0
    }
}

@media (min-width: 1200px) {
    .section-margin {
        padding: 60px 0
    }
}

.section-margin--medium {
    margin: 40px 0
}

@media (min-width: 800px) {
    .section-margin--medium {
        margin: 80px 0
    }
}

@media (min-width: 1200px) {
    .section-margin--medium {
        margin: 120px 0
    }
}

.section-padding {
    padding: 60px 0
}

@media (min-width: 1000px) {
    .section-padding {
        padding: 100px 0
    }
}

@media (min-width: 1200px) {
    .section-padding {
        padding: 135px 0
    }
}

.section-padding--small {
    padding: 60px 0
}

@media (min-width: 1000px) {
    .section-padding--small {
        padding: 100px 0
    }
}

.bg-magnolia {
    background: #faf9ff
}

.header_area {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99;
    transition: background 0.4s, all 0.3s linear
}

.header_area .navbar-right {
    min-width: 200px;
    text-align: right
}

@media (max-width: 991px) {
    .header_area .navbar-right {
        margin: 20px 0
    }
}

.header_area .navbar-brand {
    margin-right: 30px
}

.header_area .navbar-social {
    min-width: 95px;
    float: right
}

.header_area .navbar-social li {
    margin-right: 12px
}

.header_area .navbar-social li i, .header_area .navbar-social li span {
    font-size: 13px;
    color: #242429
}

.header_area .navbar-social li:last-child {
    margin-right: 0
}

.header_area .navbar-social li a:hover i, .header_area .navbar-social li span {
    color: #21146a
}

.header_area .menu_nav {
    width: 100%
}

.header_area .navbar {
    background: transparent;
    padding: 5px 0;
    border: 0px;
    border-radius: 0px;
    width: 100%
}

@media (min-width: 991px) {
    .header_area .navbar {
        padding: 20px 0
    }
}

.header_area .navbar .nav .nav-item {
    margin-right: 15px
}

.header_area .navbar .nav .nav-item .nav-link {
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 700;
    display: inline-block
}

.header_area .navbar .nav .nav-item .nav-link:after {
    display: none
}

.header_area .navbar .nav .nav-item:hover .nav-link, .header_area .navbar .nav .nav-item.active .nav-link {
    color: #fff;
    opacity: .75
}

.header_area .navbar .nav .nav-item.submenu {
    position: relative
}

.header_area .navbar .nav .nav-item.submenu ul {
    border: none;
    padding: 0px;
    border-radius: 0px;
    box-shadow: none;
    margin: 0px;
    background: #fff
}

@media (min-width: 992px) {
    .header_area .navbar .nav .nav-item.submenu ul {
        position: absolute;
        top: 120%;
        left: 0px;
        min-width: 200px;
        text-align: left;
        opacity: 0;
        transition: all 300ms ease-in;
        visibility: hidden;
        display: block;
        border: none;
        padding: 0px;
        border-radius: 0px;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.4)
    }
}

.header_area .navbar .nav .nav-item.submenu ul:before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 10px 0 10px;
    border-color: #eeeeee transparent transparent transparent;
    position: absolute;
    right: 24px;
    top: 45px;
    z-index: 3;
    opacity: 0;
    transition: all 400ms linear
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item {
    display: block;
    float: none;
    margin-right: 0px;
    border-bottom: 1px solid #ededed;
    margin-left: 0px;
    transition: all 0.4s linear
}
.navbar{
    padding:0px!important;
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
    line-height: 45px;
    color: #21146a !important;
    padding: 0px 30px;
    transition: all 150ms linear;
    display: block;
    margin-right: 0px
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:last-child {
    border-bottom: none
}

.header_area .navbar .nav .nav-item.submenu ul .nav-item:hover .nav-link {
    background-color: #21146a;
    color: #fff !important
}

@media (min-width: 992px) {
    .header_area .navbar .nav .nav-item.submenu:hover ul {
        visibility: visible;
        opacity: 1;
        top: 100%
    }
}

.header_area .navbar .nav .nav-item.submenu:hover ul .nav-item {
    margin-top: 0px
}

.header_area .navbar .nav .nav-item:last-child {
    margin-right: 0px
}

.header_area .navbar .search {
    font-size: 12px;
    line-height: 60px;
    display: inline-block;
    margin-left: 80px
}

.header_area .navbar .search i {
    font-weight: 600
}

.header_area.navbar_fixed .main_menu {
    position: fixed;
    width: 100%;
    top: -70px;
    left: 0;
    right: 0;
    background: #412fb3;
    z-index: 99;
    transform: translateY(70px);
    transition: transform 500ms ease, background 500ms ease;
    -webkit-transition: transform 500ms ease, background 500ms ease;
    box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.1)
}

.header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
    line-height: 20px
}

@media (min-width: 992px) {
    .header_area.white_menu .navbar .navbar-brand img {
        display: none
    }

    .header_area.white_menu .navbar .navbar-brand img + img {
        display: inline-block
    }
}

@media (max-width: 991px) {
    .header_area.white_menu .navbar .navbar-brand img {
        display: inline-block
    }

    .header_area.white_menu .navbar .navbar-brand img + img {
        display: none
    }
}

.header_area.white_menu .navbar .nav .nav-item .nav-link {
    color: #fff
}

.header_area.white_menu.navbar_fixed .main_menu .navbar .navbar-brand img {
    display: inline-block
}

.header_area.white_menu.navbar_fixed .main_menu .navbar .navbar-brand img + img {
    display: none
}

.header_area.white_menu.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
    line-height: 70px
}

.top_menu {
    background: #ec9d5f
}

.top_menu .float-left a {
    line-height: 40px;
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    margin-right: 50px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.top_menu .float-left a:last-child {
    margin-right: 0px
}

.top_menu .float-right .pur_btn {
    color: #fff;
    line-height: 40px;
    display: block;
    padding: 0px 40px;
    font-weight: 500;
    font-size: 12px
}

@media (max-width: 1619px) {
    .header_area .navbar .search {
        margin-left: 40px
    }
}

@media (max-width: 1199px) {
    .header_area .navbar .nav .nav-item {
        margin-right: 28px
    }

    .home_banner_area .banner_inner {
        padding: 100px 0px
    }

    .header_area .navbar .tickets_btn {
        margin-left: 40px
    }

    .home_banner_area .banner_inner .banner_content h2 br {
        display: none;
        font-size: 36px
    }

    .home_banner_area .banner_inner .banner_content h2 {
        font-size: 36px;
        line-height: 45px
    }
}

@media (max-width: 991px) {
    .navbar-toggler {
        border: none;
        border-radius: 0px;
        padding: 0px;
        cursor: pointer;
        margin-top: 27px;
        margin-bottom: 23px
    }

    .header_area .navbar {
        background: #221c5a
    }

    .navbar-toggler[aria-expanded="false"] span:nth-child(2) {
        opacity: 1
    }

    .navbar-toggler[aria-expanded="true"] span:nth-child(2) {
        opacity: 0
    }

    .navbar-toggler[aria-expanded="true"] span:first-child {
        transform: rotate(-45deg);
        position: relative;
        top: 7.5px
    }

    .navbar-toggler[aria-expanded="true"] span:last-child {
        transform: rotate(45deg);
        bottom: 6px;
        position: relative
    }

    .navbar-toggler span {
        display: block;
        width: 25px;
        height: 3px;
        background: #fff;
        margin: auto;
        margin-bottom: 4px;
        transition: all 400ms linear;
        cursor: pointer
    }

    .navbar .container {
        padding-left: 15px;
        padding-right: 15px
    }

    .nav {
        padding: 0px 0px
    }

    .header_area + section, .header_area + row, .header_area + div {
        margin-top: 117px
    }

    .header_top .nav {
        padding: 0px
    }

    .header_area .navbar .nav .nav-item .nav-link {
        line-height: 30px;
        margin-right: 0px;
        display: block;
        border-bottom: 1px solid #ededed33;
        border-radius: 0px
    }

    .header_area .navbar .search {
        margin-left: 0px
    }

    .header_area .navbar-collapse {
        max-height: 340px;
        overflow-y: scroll
    }

    .header_area .navbar .nav .nav-item.submenu ul .nav-item .nav-link {
        padding: 0px 15px
    }

    .header_area .navbar .nav .nav-item {
        margin-right: 0px
    }

    .header_area + section, .header_area + row, .header_area + div {
        margin-top: 41px
    }

    .home_banner_area .banner_inner .banner_content .banner_map_img {
        display: none
    }

    .header_area.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
        line-height: 40px
    }

    .header_area.white_menu.navbar_fixed .main_menu .navbar .nav .nav-item .nav-link {
        line-height: 40px
    }

    .header_area.white_menu .navbar .nav .nav-item .nav-link {
        color: #222
    }

    .categories_post img {
        width: 100%
    }

    .categories_post {
        max-width: 360px;
        margin: 0 auto
    }

    .blog_categorie_area .col-lg-4 {
        margin-top: 30px
    }

    .blog_area {
        padding-bottom: 80px
    }

    .single-post-area .blog_right_sidebar {
        margin-top: 30px
    }

    .contact_info {
        margin-bottom: 50px
    }

    .home_banner_area .donation_inner {
        margin-bottom: -30px
    }

    .home_banner_area .dontation_item {
        max-width: 350px;
        margin: auto
    }

    .footer_area .col-sm-6 {
        margin-bottom: 30px
    }

    .footer_area .footer_inner {
        margin-bottom: -30px
    }

    .news_widget {
        padding-left: 0px
    }

    .home_banner_area .banner_inner .home_left_img {
        display: none
    }

    .header_area .navbar .tickets_btn {
        display: none
    }

    .home_banner_area .banner_inner {
        padding: 70px 0px 100px 0px
    }

    .left_side_text {
        margin-bottom: 50px
    }

    .price_item {
        max-width: 360px;
        margin: 0px auto 30px
    }

    .price_inner {
        margin-bottom: -30px
    }
}

@media (max-width: 767px) {
    .home_banner_area {
        min-height: 500px
    }

    .home_banner_area .banner_inner {
        min-height: 500px
    }

    .home_banner_area .banner_inner .banner_content {
        margin-top: 0px
    }

    .blog_banner .banner_inner .blog_b_text h2 {
        font-size: 40px;
        line-height: 50px
    }

    .blog_info.text-right {
        text-align: left !important;
        margin-bottom: 10px
    }

    .home_banner_area .banner_inner .banner_content h3 {
        font-size: 30px
    }

    .home_banner_area .banner_inner .banner_content p br {
        display: none
    }

    .home_banner_area .banner_inner .banner_content h3 span {
        line-height: 45px;
        padding-bottom: 0px;
        padding-top: 0px
    }

    .footer-bottom {
        text-align: center
    }

    .footer-bottom .footer-social {
        text-align: center;
        margin-top: 15px
    }

    .made_life_inner .nav.nav-tabs li {
        flex: 0 0 50%;
        max-width: 50%;
        margin-bottom: 15px
    }

    .made_life_inner .nav.nav-tabs {
        margin-bottom: -15px
    }

    .made_life_area.made_white .left_side_text {
        margin-bottom: 0px;
        margin-top: 30px
    }
}

@media (max-width: 575px) {
    .top_menu {
        display: none
    }

    .header_area + section, .header_area + row, .header_area + div {
        margin-top: 51px
    }

    .home_banner_area .banner_inner .banner_content h2 {
        font-size: 28px;
        line-height: 38px
    }

    .home_banner_area {
        min-height: 450px
    }

    .home_banner_area .banner_inner {
        min-height: 450px
    }

    .blog_banner .banner_inner .blog_b_text {
        margin-top: 0px
    }

    .home_banner_area .banner_inner .banner_content img {
        display: none
    }

    .home_banner_area .banner_inner .banner_content h5 {
        margin-top: 0px
    }

    .p_120 {
        padding-top: 70px;
        padding-bottom: 70px
    }

    .main_title h2 {
        font-size: 25px
    }

    .sample-text-area {
        padding: 70px 0 70px 0
    }

    .generic-blockquote {
        padding: 30px 15px 30px 30px
    }

    .blog_details h2 {
        font-size: 20px;
        line-height: 30px
    }

    .blog_banner .banner_inner .blog_b_text h2 {
        font-size: 28px;
        line-height: 38px
    }

    .footer-area {
        padding: 70px 0px
    }

    .pad_top {
        padding-top: 70px
    }

    .pad_btm {
        padding-bottom: 70px
    }
}

@media (max-width: 480px) {
    .header_area .navbar-collapse {
        max-height: 250px
    }

    .home_banner_area .banner_inner .banner_content {
        padding: 30px 15px;
        margin-top: 0px
    }

    .banner_content .white_btn {
        display: block
    }

    .home_banner_area .banner_inner .banner_content h3 {
        font-size: 24px
    }

    .banner_area .banner_inner .banner_content h2 {
        font-size: 32px
    }

    .comments-area .thumb {
        margin-right: 10px
    }

    .testi_item .media .d-flex {
        padding-right: 0px
    }

    .testi_item .media .d-flex img {
        width: auto;
        margin-bottom: 15px
    }

    .testi_item .media {
        display: block
    }

    .banner_btn {
        margin-bottom: 20px
    }
}
.customClass {
    padding:20px;
    border:none
}


.hero-banner {
    position: relative;
    background-size: cover;
    height: 840px
}
.hero-banner-result {
    position: relative;
    background-size: cover;
    height: 300px;
    padding: 60px 0 20px 120px!important;
}

.hero-banner-news {
    position: relative;
    background-size: cover;
    height: 300px;
    padding: 60px 0 20px 120px!important;
}

.hero-banner-contact {
    position: relative;
    background-size: cover;
    height: 320px;
    padding: 60px 0 20px 120px!important;
}

.hero-banner-consortium {
    position: relative;
    background-size: cover;
    padding: 60px 0 20px 120px!important;
    display: block;
    height: 100%;
}

.hero-banner-impact {
    position: relative;
    background-size: cover;
    height: 640px
}

.hero-banner-about {
    position: relative;
    background-size: cover;
    height: 560px
}

@media (min-width: 600px) {
    .hero-banner {
        padding: 150px 0 120px 0
    }
}

@media (min-width: 991px) {
    .hero-banner {
        padding: 175px 0 160px 0
    }
}

@media (min-width: 1200px) {
    .hero-banner {
        padding: 160px 0 160px 0
    }
}

.hero-banner__img {
    position: relative
}

@media (min-width: 1200px) {
    .hero-banner__img img {
        position: absolute;
        top: 0;
    }
}

.hero-banner__content h1 {
    color: #fff;
    margin-bottom: 22px
}

.hero-banner__content p {
    color: #fff;
    margin-bottom: 45px
}

.hero-banner::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(./img/banner/BG.png) left center no-repeat;
    background-size: cover;
    z-index: -1
}

.hero-banner-about::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(./img/banner/about-bg.jpg) left center no-repeat;
    background-size: cover;
    z-index: -1
}

.hero-banner-impact::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(./img/banner/impact-bg.jpg) left center no-repeat;
    background-size: cover;
    z-index: -1
}
.hero-banner-news::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(./img/banner/bg-news.jpg) left center no-repeat;
    background-size: cover;
    z-index: -1
}

.hero-banner-consortium::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(./img/banner/map_bg.jpg) left center no-repeat;
    background-size: cover;
    z-index: -1
}

.hero-banner--sm {
    padding: 0;
    height: 300px
}

.hero-banner--sm::after {
    height: 100%
}

@media (min-width: 1000px) {
    .hero-banner--sm {
        height: 500px
    }
}

.hero-banner--sm__content {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    margin-top: 20px;
    text-align: center
}

@media (min-width: 600px) {
    .hero-banner--sm__content {
        margin-top: 40px
    }
}

.hero-banner--sm__content > * {
    color: #fff
}

.hero-banner--sm__content h3 {
    font-size: 27px;
    margin-bottom: 0
}

.hero-banner--sm__content h1 {
    margin-bottom: 10px
}

.hero-banner--sm__content h4 {
    font-size: 18px
}

.banner-breadcrumb {
    display: inline-block
}

.banner-breadcrumb .breadcrumb {
    background: transparent;
    padding: 0
}

.banner-breadcrumb .breadcrumb-item {
    padding: .1rem
}

.banner-breadcrumb .breadcrumb-item a {
    text-transform: capitalize;
    color: #fff
}

.banner-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    color: #fff;
    padding-left: .2rem;
    padding-right: .4rem
}

.banner-breadcrumb .breadcrumb-item.active {
    color: #fff
}

.section-intro {
    max-width: 630px;
    margin-right: auto;
    margin-left: auto
}

.section-intro__title {
    height: 57px;
    width: 100%;
    color: #1B2848;
    font-family: 'Roboto';
    font-size: 48px;
    font-weight: bold;
    letter-spacing: -0.4px;
    line-height: 57px;
}

.section-intro__subtitle {
    margin-bottom: 0;
    height: 28px;
    width: 100%;
    color: #1B2848;
    font-family: 'Roboto';
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -0.2px;

}
.home-card{
    box-sizing: border-box;
    height: 320px;
    border: 1px solid #0A8AFF;
    border-radius: 8px;
    padding:10px
}

.card-feature {
    padding: 30px 35px;
    border: 0;
    border-radius: 0;
    background: #faf9ff;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.card-feature__icon {
    margin-top:-50px;
    margin-bottom: 12px
}

.card-feature__icon i, .card-feature__icon span {
    font-size: 30px;
    color: #21146a
}

.card-feature__title {

    height: 40px;
    color: #1B2848;
    font-family: Roboto;
    font-size: 25px;
    font-weight: 500;
    letter-spacing: -0.28px;
    line-height: 40px;
}

.card-feature__subtitle {
    margin-bottom: 0;

    height: 106px;
    color: #4A4A4A;
    font-family: Barlow;
    font-size: 16px;
    letter-spacing: -0.05px;
    line-height: 19px;
    text-align: center;
}

.card-feature:hover {
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(33, 20, 106, 0.1)
}

.about__content h2 {
    margin-bottom: 20px
}

.about__content p {
    margin-bottom: 38px
}

.offer-single {
    margin-bottom: 30px
}

.the-consortium-cons {
    color: #1B2848;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    display: inline-block;

}

.about-page-bubble-main{
    height: 148px;
    width: 148px;
    border-radius: 50%;
    border: 1px solid #0A8AFF;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-left:6px;
    margin-right:6px;
}

.about-page-bubble {
    color: #0A8AFF;
    font-family: Roboto;
    font-size: 50px;
    font-weight: bold;
    margin-top: 10px;

}
.about-page-bubble-sub {
    color: #1B2848;
    font-family: Roboto;
    font-size: 24px;
    font-weight: bold;
    margin-top: -10px;

}

@media only screen and (max-width: 992px) {
    .about-bubbles {
        display: flex!important;
        margin-top:40px!important;
    }
    .about-bubble {
        width: 96px!important;
        height: 96px!important;
    }
    .about-bubble-box{
        margin:0px!important;
    }

    .hero-banner-news{
        padding: 0px 0 0px 0px!important;
    }

    .hero-banner-contact{
        height: 200px!important;
        padding: 30px 0 0px 0px!important;
    }
    .hero-banner-consortium{
        padding: 0px 0 0px 0px!important;
    }

    .hero-banner-result{
        padding: 0px 0 0px 0px!important;
    }
    .hero-banner-impact{
        padding: 0px 0 0px 0px!important;
        height: 360px
    }

    .about-page-bubble-main{
        height: 96px;
        width: 96px;
        margin-left:0px;
        margin-right:0px;
    }

    .about-page-bubble{
        font-size: 28px;
    }


    .about-page-bubble-sub{
        font-size: 14px;
    }

    .react-bootstrap-table {
        width:340px;
        overflow: auto;
    }

}

@media (min-width: 991px) {
    .offer-single {
        margin-bottom: 50px
    }
}

.offer-single__content {
    border: 0;
    border-radius: 0;
    background: #faf9ff;
    padding: 30px 23px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.offer-single__content h4 {
    font-size: 22px;
    font-weight: 600
}

.offer-single__content p {
    margin-bottom: 0
}

.offer-single__content:hover {
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(33, 20, 106, 0.1)
}

.offer-single__icon {
    display: inline-block;
    margin-bottom: 15px
}

.offer-single__icon i, .offer-single__icon span {
    font-size: 30px;
    color: #21146a
}

@media (min-width: 991px) {
    .offer-single__img {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%)
    }
}

@media (min-width: 991px) and (min-width: 1200px) {
    .offer-single__img {
        transform: translateY(0);
        top: 50px;
        left: 100px
    }
}

@media (min-width: 991px) {
    .offer-single-wrapper .offer-single:nth-child(even) {
        position: relative;
        top: 40px
    }
}

@media (min-width: 1200px) {
    .solution__img {
        position: relative;
        left: -60px
    }
}

@media (min-width: 1200px) {
    .solution__content {
        padding-left: 30px;
        padding-right: 45px
    }
}

.solution__content h2 {
    margin-bottom: 20px
}

.solution__content p {
    margin-bottom: 30px
}

.card-pricing {
    background: #faf9ff;
    border-radius: 3px;
    border: 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.card-pricing:hover {
    background: #fff;
    box-shadow: 0px 20px 25.5px 4.5px rgba(33, 20, 106, 0.1)
}

.card-pricing:hover .button {
    background: #21146a;
    color: #fff
}

.card-pricing__header {
    border-bottom: 1px solid #ebebeb;
    padding: 40px 7px
}

.card-pricing__header h4 {
    font-size: 27px;
    font-weight: 700;
    margin-bottom: 5px
}

.card-pricing__header p {
    margin-bottom: 27px
}

.card-pricing__price {
    font-size: 40px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    margin: 0
}

@media (min-width: 991px) {
    .card-pricing__price {
        font-size: 60px
    }
}

.card-pricing__price span {
    font-size: 40px;
    vertical-align: middle;
    position: relative;
    bottom: 5px
}

.card-pricing__list {
    padding: 30px 8px 25px 8px;
    border-bottom: 1px solid #ebebeb
}

.card-pricing__list li {
    margin-bottom: 12px
}

.card-pricing__list li:last-child {
    margin-bottom: 0
}

.card-pricing__list li i, .card-pricing__list li span {
    font-size: 13px;
    margin-right: 10px
}

.card-pricing__list li.unvalid i, .card-pricing__list li.unvalid span {
    color: #f81c1c;
    font-size: 12px
}

.card-pricing__footer {
    padding: 45px 0
}

.testimonial {
    max-width: 970px;
    margin-right: auto;
    margin-left: auto
}

.testimonial__img {
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 30px;
    z-index: 5
}

.testimonial__content {
    background: #fff;
    padding: 50px 10px 35px 10px;
    position: relative
}

@media (min-width: 1000px) {
    .testimonial__content {
        padding: 50px 20px 35px 20px
    }
}

.testimonial__content h3 {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 0
}

.testimonial__content p {
    font-size: 14px
}

.testimonial__i {
    font-size: 15px !important;
    font-style: italic;
    margin-bottom: 0
}

.testimonial__icon {
    font-size: 173px;
    color: rgba(119, 119, 119, 0.1);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 120px
}

.testimonial .owl-dots {
    margin-top: 40px !important
}

.testimonial .owl-dots .owl-dot span {
    width: 20px;
    height: 6px;
    background: #d3d2d5;
    border-radius: 2px;
    margin: 5px 10px
}

.testimonial .owl-dots .owl-dot.active span {
    background: #21146a
}

.our_major_cause .owl-item {
    padding: 0 10px;
    margin-bottom: 50px
}

@media (max-width: 576px) {
    .our_major_cause .owl-item {
        margin-bottom: 0
    }
}

.our_major_cause .card {
    border: 0;
    box-shadow: 0 3px 14px 0px rgba(221, 221, 221, 0.85);
    margin-bottom: 30px;
    overflow: hidden
}

@media (max-width: 576px) {
    .our_major_cause .card .card-body {
        padding: 5px
    }
}

.our_major_cause .card figure {
    overflow: hidden;
    margin: 0
}

.our_major_cause .card figure img {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.our_major_cause .card .card_inner_body {
    padding: 20px 12px 30px
}

.our_major_cause .card .card_inner_body .card-body-top {
    margin-bottom: 30px
}

.our_major_cause .card .card-text {
    margin-bottom: 0
}

.our_major_cause .card .progress {
    overflow: visible;
    height: 4px;
    position: relative;
    background: rgba(255, 255, 255, 0.2);
    top: -4px
}

.our_major_cause .card .progress .progress-bar span {
    position: absolute;
    bottom: -49px;
    right: 11px;
    font-size: 14px
}

.our_major_cause .card:hover img {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2)
}

.our_major_cause .card:hover .main_btn2 {
    color: #fff
}

.clients_logo_area .clients_slider .item {
    text-align: center
}

.clients_logo_area .clients_slider .item img {
    width: auto;
    text-align: center;
    display: block;
    margin: auto;
    line-height: 70px;
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    filter: grayscale(100%);
    opacity: .2;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.clients_logo_area .clients_slider .item:hover img {
    opacity: 1;
    -webkit-filter: grayscale(0%);
    -moz-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    filter: grayscale(0%)
}

.says_left {
    max-width: 335px
}

.says_left h3 {
    font-size: 36px;
    line-height: 45px;
    margin-bottom: 20px;
    margin-top: 30px
}

.says_left p {
    margin-bottom: 0px
}

.says_item {
    background: #fff;
    border: 1px solid #eeeeee;
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 55px;
    padding-bottom: 55px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.says_item h4 {
    font-size: 20px;
    margin-bottom: 13px
}

.says_item h5 {
    font-weight: 300;
    font-size: 14px;
    margin-bottom: 32px
}

.says_item p {
    margin-bottom: 0px
}

.says_item:hover {
    box-shadow: 0px 10px 30px 0px rgba(98, 71, 234, 0.1);
    border-color: #fff
}

.about_us .about_img:after {
    content: '';
    position: absolute;
    top: 30px;
    bottom: -5px;
    left: -10px;
    right: -10px;
    z-index: 1
}

@media (max-width: 1024px) {
    .about_us .about_img:after {
        background: #fff
    }
}

.about_us .about_img img {
    position: relative;
    z-index: 2;
    box-shadow: 0px 13px 35px #c9c9c9
}

.about_us .content_wrapper {
    padding: 25px 0
}

.about_us .content_wrapper h1 {
    margin-bottom: 40px
}

.about_us .content_wrapper .main_btn {
    margin-top: 50px
}

.about_us .bottom_para {
    margin-top: 50px
}

.recent_event .single_event {
    margin-bottom: 30px
}

.recent_event .single_event figure {
    overflow: hidden;
    margin: 0;
    position: relative
}

.recent_event .single_event figure img {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.recent_event .single_event .content_wrapper {
    padding: 22px 28px 10px 0px
}

@media (max-width: 1024px) {
    .recent_event .single_event .content_wrapper {
        padding: 0
    }
}

@media (max-width: 1024px) {
    .recent_event .single_event .content_wrapper {
        margin-top: 0
    }
}

.recent_event .single_event .content_wrapper .date_time {
    font-size: 12px;
    margin-bottom: 10px
}

@media (max-width: 1024px) {
    .recent_event .single_event .content_wrapper .date_time {
        margin-bottom: 0
    }
}

.recent_event .single_event .content_wrapper .title {
    margin-bottom: 20px
}

@media (max-width: 1024px) {
    .recent_event .single_event .content_wrapper .title {
        margin-bottom: 10
    }
}

.recent_event .single_event:hover img {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2)
}

.recent_event .single_event:hover .img-overlay {
    background: initial
}

.event_details .event_content {
    margin-top: 40px
}

.event_details .left_content {
    margin-top: 38px
}

@media (max-width: 991px) {
    .event_details .left_content {
        margin-top: 0
    }
}

.event_details .left_content p i {
    margin-right: 10px
}

.event_details .right_content h2 {
    margin-bottom: 20px
}

.event_details .right_content p:last-child {
    margin-bottom: 0
}

.blog-banner {
    position: relative
}

.blog-banner h4, .blog-banner h1, .blog-banner a {
    text-transform: capitalize;
    color: #fff
}

.blog-banner h4 {
    font-size: 18px;
    margin-bottom: 15px
}

.blog-banner h1 {
    font-size: 30px;
    margin-bottom: 10px;
    color: #3a414e
}

@media (min-width: 992px) {
    .blog-banner h1 {
        font-size: 36px
    }
}

.blog-banner-area {
    height: 280px;
    position: relative;
    z-index: 1
}

@media (min-width: 1000px) {
    .blog-banner-area {
        height: 410px
    }
}

.blog-banner-area .blog-banner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%)
}

.blog-banner-area::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f1f6f7;
    z-index: -1
}

.blog-banner-btn {
    border: 2px solid #21146a;
    padding: 10px 45px
}

.blog-banner-btn:hover {
    background: #21146a;
    color: #fff
}

.l_blog_item .l_blog_text .date {
    margin-top: 24px;
    margin-bottom: 15px
}

.l_blog_item .l_blog_text .date a {
    font-size: 12px
}

.l_blog_item .l_blog_text h4 {
    font-size: 18px;
    color: #3a414e;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 0px;
    padding-bottom: 20px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.l_blog_item .l_blog_text h4:hover {
    color: #21146a
}

.l_blog_item .l_blog_text p {
    margin-bottom: 0px;
    padding-top: 20px
}

.causes_slider .owl-dots {
    text-align: center;
    margin-top: 80px
}

.causes_slider .owl-dots .owl-dot {
    height: 14px;
    width: 14px;
    background: #eeeeee;
    display: inline-block;
    margin-right: 7px
}

.causes_slider .owl-dots .owl-dot:last-child {
    margin-right: 0px
}

.causes_slider .owl-dots .owl-dot.active {
    background: #21146a
}

.causes_item {
    background: #fff
}

.causes_item .causes_img {
    position: relative
}

.causes_item .causes_img .c_parcent {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
    height: 3px;
    background: rgba(255, 255, 255, 0.5)
}

.causes_item .causes_img .c_parcent span {
    width: 70%;
    height: 3px;
    background: #21146a;
    position: absolute;
    left: 0px;
    bottom: 0px
}

.causes_item .causes_img .c_parcent span:before {
    content: "75%";
    position: absolute;
    right: -10px;
    bottom: 0px;
    background: #21146a;
    color: #fff;
    padding: 0px 5px
}

.causes_item .causes_text {
    padding: 30px 35px 40px 30px
}

.causes_item .causes_text h4 {
    color: #3a414e;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    cursor: pointer
}

.causes_item .causes_text h4:hover {
    color: #21146a
}

.causes_item .causes_text p {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 0px
}

.causes_item .causes_bottom a {
    width: 50%;
    border: 1px solid #21146a;
    text-align: center;
    float: left;
    line-height: 50px;
    background: #21146a;
    color: #fff;
    font-size: 14px;
    font-weight: 500
}

.causes_item .causes_bottom a + a {
    border-color: #eeeeee;
    background: #fff;
    font-size: 14px;
    color: #3a414e
}

.latest_blog_area {
    background: #f9f9ff
}

.single-recent-blog-post {
    margin-bottom: 30px
}

.single-recent-blog-post .thumb {
    overflow: hidden
}

.single-recent-blog-post .thumb img {
    transition: all 0.7s linear
}

.single-recent-blog-post .details {
    padding-top: 30px
}

.single-recent-blog-post .details .sec_h4 {
    line-height: 24px;
    padding: 10px 0px 13px;
    transition: all 0.3s linear
}

.single-recent-blog-post .date {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400
}

.single-recent-blog-post:hover img {
    transform: scale(1.23) rotate(10deg)
}

.tags .tag_btn {
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    border: 1px solid #eeeeee;
    display: inline-block;
    padding: 1px 18px;
    text-align: center;
    color: #3a414e
}

.tags .tag_btn:before {
    background: #21146a
}

.tags .tag_btn + .tag_btn {
    margin-left: 2px
}

.blog_categorie_area {
    padding-top: 30px;
    padding-bottom: 30px
}

@media (min-width: 900px) {
    .blog_categorie_area {
        padding-top: 80px;
        padding-bottom: 80px
    }
}

@media (min-width: 1100px) {
    .blog_categorie_area {
        padding-top: 120px;
        padding-bottom: 120px
    }
}

.categories_post {
    position: relative;
    text-align: center;
    cursor: pointer
}

.categories_post img {
    max-width: 100%
}

.categories_post .categories_details {
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    background: rgba(34, 34, 34, 0.75);
    color: #fff;
    transition: all 0.3s linear;
    display: flex;
    align-items: center;
    justify-content: center
}

.categories_post .categories_details h5 {
    margin-bottom: 0px;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    color: #fff;
    position: relative
}

.categories_post .categories_details p {
    font-weight: 300;
    font-size: 14px;
    line-height: 26px;
    margin-bottom: 0px
}

.categories_post .categories_details .border_line {
    margin: 10px 0px;
    background: #fff;
    width: 100%;
    height: 1px
}

.categories_post:hover .categories_details {
    background: rgba(56, 74, 235, 0.85)
}

.blog_item {
    margin-bottom: 40px
}

.blog_info {
    padding-top: 30px
}

.blog_info .post_tag {
    padding-bottom: 20px
}

.blog_info .post_tag a {
    color: #3a414e;
    font-size: 14px
}

.blog_info .post_tag a.active {
    color: #21146a
}

.blog_info .blog_meta li a {
    color: #777777;
    vertical-align: middle;
    padding-bottom: 10px;
    display: inline-block;
    font-size: 14px
}

.blog_info .blog_meta li a i {
    color: #3a414e;
    font-size: 16px;
    font-weight: 600;
    padding-left: 15px;
    line-height: 20px;
    vertical-align: middle
}

.blog_info .blog_meta li a:hover {
    color: #21146a
}

.blog_post img {
    max-width: 100%
}

.blog_details {
    padding-top: 20px
}

.blog_details h2 {
    font-size: 24px;
    line-height: 36px;
    color: #3a414e;
    font-weight: 600;
    transition: all 0.3s linear
}

.blog_details h2:hover {
    color: #21146a
}

.blog_details p {
    margin-bottom: 26px
}

.view_btn {
    font-size: 14px;
    line-height: 36px;
    display: inline-block;
    color: #3a414e;
    font-weight: 500;
    padding: 0px 30px;
    background: #fff
}

.blog_right_sidebar {
    border: 1px solid #eeeeee;
    background: #fafaff;
    padding: 30px
}

.blog_right_sidebar .widget_title {
    font-size: 18px;
    line-height: 25px;
    background: #21146a;
    text-align: center;
    color: #fff;
    padding: 8px 0px;
    margin-bottom: 30px
}

.blog_right_sidebar .search_widget .input-group .form-control {
    font-size: 14px;
    line-height: 29px;
    border: 0px;
    width: 100%;
    font-weight: 300;
    color: #fff;
    padding-left: 20px;
    border-radius: 45px;
    z-index: 0;
    background: #21146a
}

.blog_right_sidebar .search_widget .input-group .form-control.placeholder {
    color: #fff
}

.blog_right_sidebar .search_widget .input-group .form-control:-moz-placeholder {
    color: #fff
}

.blog_right_sidebar .search_widget .input-group .form-control::-moz-placeholder {
    color: #fff
}

.blog_right_sidebar .search_widget .input-group .form-control::-webkit-input-placeholder {
    color: #fff
}

.blog_right_sidebar .search_widget .input-group .form-control:focus {
    box-shadow: none
}

.blog_right_sidebar .search_widget .input-group .btn-default {
    position: absolute;
    right: 20px;
    background: transparent;
    border: 0px;
    box-shadow: none;
    font-size: 14px;
    color: #fff;
    padding: 0px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1
}

.blog_right_sidebar .author_widget {
    text-align: center
}

.blog_right_sidebar .author_widget h4 {
    font-size: 18px;
    line-height: 20px;
    color: #3a414e;
    margin-bottom: 5px;
    margin-top: 30px
}

.blog_right_sidebar .author_widget p {
    margin-bottom: 0px
}

.blog_right_sidebar .author_widget .social_icon {
    padding: 7px 0px 15px
}

.blog_right_sidebar .author_widget .social_icon a {
    font-size: 14px;
    color: #3a414e;
    transition: all 0.2s linear
}

.blog_right_sidebar .author_widget .social_icon a + a {
    margin-left: 20px
}

.blog_right_sidebar .author_widget .social_icon a:hover {
    color: #21146a
}

.blog_right_sidebar .popular_post_widget .post_item .media-body {
    justify-content: center;
    align-self: center;
    padding-left: 20px
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3 {
    font-size: 14px;
    line-height: 20px;
    color: #3a414e;
    margin-bottom: 4px;
    transition: all 0.3s linear
}

.blog_right_sidebar .popular_post_widget .post_item .media-body h3:hover {
    color: #21146a
}

.blog_right_sidebar .popular_post_widget .post_item .media-body p {
    font-size: 12px;
    line-height: 21px;
    margin-bottom: 0px
}

.blog_right_sidebar .popular_post_widget .post_item + .post_item {
    margin-top: 20px
}

.blog_right_sidebar .post_category_widget .cat-list li {
    border-bottom: 2px dotted #eee;
    transition: all 0.3s ease 0s;
    padding-bottom: 12px
}

.blog_right_sidebar .post_category_widget .cat-list li a {
    font-size: 14px;
    line-height: 20px;
    color: #777
}

.blog_right_sidebar .post_category_widget .cat-list li a p {
    margin-bottom: 0px
}

.blog_right_sidebar .post_category_widget .cat-list li + li {
    padding-top: 15px
}

.blog_right_sidebar .post_category_widget .cat-list li:hover {
    border-color: #21146a
}

.blog_right_sidebar .post_category_widget .cat-list li:hover a {
    color: #21146a
}

.blog_right_sidebar .newsletter_widget {
    text-align: center
}

.blog_right_sidebar .newsletter_widget .form-group {
    margin-bottom: 8px
}

.blog_right_sidebar .newsletter_widget .input-group-prepend {
    margin-right: -1px
}

.blog_right_sidebar .newsletter_widget .input-group-text {
    background: #fff;
    border-radius: 0px;
    vertical-align: top;
    font-size: 12px;
    line-height: 36px;
    padding: 0px 0px 0px 15px;
    border: 1px solid #eeeeee;
    border-right: 0px
}

.blog_right_sidebar .newsletter_widget .input-group-text i {
    color: #cccccc
}

.blog_right_sidebar .newsletter_widget .form-control {
    font-size: 12px;
    line-height: 24px;
    color: #cccccc;
    border: 1px solid #eeeeee;
    border-left: 0px;
    border-radius: 0px
}

.blog_right_sidebar .newsletter_widget .form-control.placeholder {
    color: #cccccc
}

.blog_right_sidebar .newsletter_widget .form-control:-moz-placeholder {
    color: #cccccc
}

.blog_right_sidebar .newsletter_widget .form-control::-moz-placeholder {
    color: #cccccc
}

.blog_right_sidebar .newsletter_widget .form-control::-webkit-input-placeholder {
    color: #cccccc
}

.blog_right_sidebar .newsletter_widget .form-control:focus {
    outline: none;
    box-shadow: none
}

.blog_right_sidebar .newsletter_widget .bbtns {
    background: #21146a;
    color: #fff;
    font-size: 12px;
    line-height: 38px;
    display: inline-block;
    font-weight: 500;
    padding: 0px 24px 0px 24px;
    border-radius: 0
}

.blog_right_sidebar .newsletter_widget .text-bottom {
    font-size: 12px
}

.blog_right_sidebar .tag_cloud_widget ul li {
    display: inline-block
}

.blog_right_sidebar .tag_cloud_widget ul li a {
    display: inline-block;
    border: 1px solid #eee;
    background: #fff;
    padding: 0px 13px;
    margin-bottom: 8px;
    transition: all 0.3s ease 0s;
    color: #3a414e;
    font-size: 12px
}

.blog_right_sidebar .tag_cloud_widget ul li a:hover {
    background: #21146a;
    color: #fff
}

.blog_right_sidebar .br {
    width: 100%;
    height: 1px;
    background: #eee;
    margin: 30px 0px
}

.blog-pagination {
    padding-top: 25px;
    padding-bottom: 95px
}

.blog-pagination .page-link {
    border-radius: 0
}

.blog-pagination .page-item {
    border: none
}

.page-link {
    background: transparent;
    font-weight: 400
}

.blog-pagination .page-item.active .page-link {
    background-color: #21146a;
    border-color: transparent;
    color: #fff
}

.blog-pagination .page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #8a8a8a;
    border: none
}

.blog-pagination .page-link .lnr {
    font-weight: 600
}

.blog-pagination .page-item:last-child .page-link, .blog-pagination .page-item:first-child .page-link {
    border-radius: 0
}

.blog-pagination .page-link:hover {
    color: #fff;
    text-decoration: none;
    background-color: #21146a;
    border-color: #eee
}

.single-post-area .social-links {
    padding-top: 10px
}

.single-post-area .social-links li {
    display: inline-block;
    margin-bottom: 10px
}

.single-post-area .social-links li a {
    color: #cccccc;
    padding: 7px;
    font-size: 14px;
    transition: all 0.2s linear
}

.single-post-area .social-links li a:hover {
    color: #3a414e
}

.single-post-area .blog_details {
    padding-top: 26px
}

.single-post-area .blog_details p {
    margin-bottom: 10px
}

.single-post-area .quotes {
    margin-top: 20px;
    margin-bottom: 30px;
    padding: 24px 35px 24px 30px;
    background-color: #fff;
    box-shadow: -20.84px 21.58px 30px 0px rgba(176, 176, 176, 0.1);
    font-size: 14px;
    line-height: 24px;
    color: #777;
    font-style: italic
}

.single-post-area .arrow {
    position: absolute
}

.single-post-area .arrow .lnr {
    font-size: 20px;
    font-weight: 600
}

.single-post-area .thumb .overlay-bg {
    background: rgba(0, 0, 0, 0.8)
}

.single-post-area .navigation-area {
    border-top: 1px solid #eee;
    padding-top: 30px;
    margin-top: 60px
}

.single-post-area .navigation-area p {
    margin-bottom: 0px
}

.single-post-area .navigation-area h4 {
    font-size: 18px;
    line-height: 25px;
    color: #3a414e
}

.single-post-area .navigation-area .nav-left {
    text-align: left
}

.single-post-area .navigation-area .nav-left .thumb {
    margin-right: 20px;
    background: #000
}

.single-post-area .navigation-area .nav-left .thumb img {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.single-post-area .navigation-area .nav-left .lnr {
    margin-left: 20px;
    opacity: 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.single-post-area .navigation-area .nav-left:hover .lnr {
    opacity: 1
}

.single-post-area .navigation-area .nav-left:hover .thumb img {
    opacity: .5
}

@media (max-width: 767px) {
    .single-post-area .navigation-area .nav-left {
        margin-bottom: 30px
    }
}

.single-post-area .navigation-area .nav-right {
    text-align: right
}

.single-post-area .navigation-area .nav-right .thumb {
    margin-left: 20px;
    background: #000
}

.single-post-area .navigation-area .nav-right .thumb img {
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.single-post-area .navigation-area .nav-right .lnr {
    margin-right: 20px;
    opacity: 0;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.single-post-area .navigation-area .nav-right:hover .lnr {
    opacity: 1
}

.single-post-area .navigation-area .nav-right:hover .thumb img {
    opacity: .5
}

@media (max-width: 991px) {
    .single-post-area .sidebar-widgets {
        padding-bottom: 0px
    }
}

.comments-area {
    background: #fafaff;
    border: 1px solid #eee;
    padding: 50px 30px;
    margin-top: 50px
}

@media (max-width: 414px) {
    .comments-area {
        padding: 50px 8px
    }
}

.comments-area h4 {
    text-align: center;
    margin-bottom: 50px;
    color: #3a414e;
    font-size: 18px
}

.comments-area h5 {
    font-size: 16px;
    margin-bottom: 0px
}

.comments-area a {
    color: #3a414e
}

.comments-area .comment-list {
    padding-bottom: 48px
}

.comments-area .comment-list:last-child {
    padding-bottom: 0px
}

.comments-area .comment-list.left-padding {
    padding-left: 25px
}

@media (max-width: 413px) {
    .comments-area .comment-list .single-comment h5 {
        font-size: 12px
    }

    .comments-area .comment-list .single-comment .date {
        font-size: 11px
    }

    .comments-area .comment-list .single-comment .comment {
        font-size: 10px
    }
}

.comments-area .thumb {
    margin-right: 20px
}

.comments-area .date {
    font-size: 13px;
    color: #cccccc;
    margin-bottom: 13px
}

.comments-area .comment {
    color: #777777;
    margin-bottom: 0px
}

.comments-area .btn-reply {
    background-color: #fff;
    color: #3a414e;
    padding: 5px 18px;
    font-size: 12px;
    display: block;
    font-weight: 400;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s
}

.comments-area .btn-reply:hover {
    background-color: #21146a;
    color: #fff;
    font-weight: 700
}

.comment-form {
    background: #fafaff;
    text-align: center;
    border: 1px solid #eee;
    padding: 47px 30px 43px;
    margin-top: 50px;
    margin-bottom: 20px
}

.comment-form .form-group {
    margin-bottom: 20px
}

.comment-form h4 {
    text-align: center;
    margin-bottom: 50px;
    font-size: 18px;
    line-height: 22px;
    color: #3a414e
}

.comment-form .name {
    padding-left: 0px
}

@media (max-width: 767px) {
    .comment-form .name {
        padding-right: 0px;
        margin-bottom: 1rem
    }
}

.comment-form .email {
    padding-right: 0px
}

@media (max-width: 991px) {
    .comment-form .email {
        padding-left: 0px
    }
}

.comment-form .form-control {
    padding: 8px 20px;
    background: #fff;
    border: none;
    border-radius: 0px;
    width: 100%;
    font-size: 14px;
    color: #777777;
    border: 1px solid transparent
}

.comment-form .form-control:focus {
    box-shadow: none;
    border: 1px solid #eee
}

.comment-form .form-control::placeholder {
    color: #777777
}

.comment-form textarea.form-control {
    height: 140px;
    resize: none
}

.comment-form ::-webkit-input-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form ::-moz-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form :-ms-input-placeholder {
    font-size: 13px;
    color: #777
}

.comment-form :-moz-placeholder {
    font-size: 13px;
    color: #777
}

.contact-banner {
    position: relative
}

.contact-banner h4, .contact-banner h1, .contact-banner a {
    text-transform: uppercase;
    color: #fff
}

.contact-banner h4 {
    font-size: 18px;
    margin-bottom: 15px
}

.contact-banner h1 {
    font-size: 37px;
    margin-bottom: 5px
}

@media (min-width: 992px) {
    .contact-banner h1 {
        font-size: 48px
    }
}

.contact-banner-area {
    height: 280px;
    background-size: cover;
    position: relative;
    z-index: 1
}

@media (min-width: 991px) {
    .contact-banner-area {
        height: 450px
    }
}

.contact-banner-area .contact-banner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%)
}

.contact-banner-area::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(1, 2, 11, 0.7);
    z-index: -1
}

.contact-banner-btn {
    border: 2px solid #21146a;
    padding: 10px 45px
}

.contact-banner-btn:hover {
    background: #21146a;
    color: #fff
}

.contact-info__icon {
    margin-right: 20px
}

.contact-info__icon i, .contact-info__icon span {
    color: #21146a;
    font-size: 20px
}

.contact-info .media-body h3 {
    font-size: 16px;
    margin-bottom: 0
}

.contact-info .media-body h3 a {
    color: #3a414e
}

.contact-info .media-body p {
    color: #999999
}

.form-contact .form-group {
    margin-bottom: 10px
}

.form-contact .form-control {
    border-radius: 0;
    height: 40px;
    padding-left: 18px;
    border-color: transparent;
    background: rgba(130, 139, 178, 0.1)
}

.form-contact .form-control:focus {
    outline: 0;
    box-shadow: none
}

.form-contact .form-control::placeholder {
    font-size: 14px;
    color: #999999
}

.form-contact .different-control {
    height: 100% !important
}

.button {
    display: inline-block;
    border: 1px solid transparent;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 500;
    padding: 10px 24px;
    border-radius: 30px;
    background-color: #fff;
    color: #21146a;
    color: #3a414e;
    transition: all .4s ease
}

.button i, .button span {
    margin-left: 5px
}

@media (min-width: 900px) {
    .button {
        padding: 12px 45px
    }
}

.button:hover {
    background-color: transparent;
    border-color: #21146a;
    color: #000022
}

.button-header {
    padding: 12px 40px
}

.button.bg:hover {
    border-color: #fff;
    color: #fff
}

.button-light {
    background-color: transparent;
    border-color: #21146a
}

.button-light:hover {
    background-color: #21146a;
    color: #fff
}

.button-blog {
    border: 1px solid #21146a;
    border-radius: 4px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
    color: #222222;
    padding: 7px 30px
}

.button-blog:hover {
    background-color: #21146a;
    color: #fff
}

.button-postComment {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    background-color: #21146a;
    color: #fff;
    border-radius: 4px;
    padding: 14px 45px
}

.button-contactForm {
    font-size: 15px;
    background-color: #21146a;
    color: #fff;
    padding: 12px 36px
}

.button-contactForm:hover {
    border-color: #21146a;
    color: #21146a
}

.footer-area {
    padding: 60px 0px 60px;
    background: #fff;
}
.powered-by {
    height: 19px;
    width: 275px;
    color: #1B2848;
    font-family: Roboto;
    font-size: 16px;
    letter-spacing: -0.35px;
    line-height: 19px;
}

@media (max-width: 991px) {
    .footer-area {
        padding: 60px 0px
    }
}

@media (max-width: 991px) {
    .footer-area .single-footer-widget {
        margin-bottom: 30px
    }
}

.footer-area .single-footer-widget p {
    color: #7b838a
}

.footer-area .single-footer-widget h4 {
    color: #fff;
    font-size: 22px;
    margin-bottom: 25px;
    font-weight: 600
}

@media (max-width: 1024px) {
    .footer-area .single-footer-widget h4 {
        font-size: 18px
    }
}

@media (max-width: 991px) {
    .footer-area .single-footer-widget h4 {
        margin-bottom: 15px
    }
}

.footer-area .single-footer-widget ul li {
    margin-bottom: 10px
}

.footer-area .single-footer-widget ul li a {
    color: #797979
}

.footer-area .single-footer-widget .form-wrap {
    margin-top: 25px
}

.footer-area .single-footer-widget input {
    height: 48px;
    border: none;
    width: 67% !important;
    font-weight: 400;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    padding-left: 20px;
    border-radius: 0;
    font-size: 14px;
    padding: 10px
}

.footer-area .single-footer-widget input:focus {
    outline: none;
    box-shadow: none
}

.footer-area .single-footer-widget input.placeholder {
    padding-left: 10px;
    color: #797979
}

.footer-area .single-footer-widget input:-moz-placeholder {
    padding-left: 10px;
    color: #797979
}

.footer-area .single-footer-widget input::-moz-placeholder {
    padding-left: 10px;
    color: #797979
}

.footer-area .single-footer-widget input::-webkit-input-placeholder {
    padding-left: 10px;
    color: #797979
}

.footer-area .single-footer-widget .click-btn {
    font-family: "Roboto", sans-serif;
    color: #3a414e;
    border-radius: 0;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding: 8px 20px;
    border: 0;
    font-size: 14px;
    background: #fff;
    font-weight: 500;
    text-transform: capitalize;
    position: relative;
    left: 0
}

.footer-area .single-footer-widget .click-btn:focus {
    outline: none;
    box-shadow: none
}

@media (max-width: 375px) {
    .footer-area .single-footer-widget .click-btn {
        margin-top: 10px
    }
}

@media (min-width: 400px) {
    .footer-area .single-footer-widget .click-btn {
        left: -50px
    }
}

.footer-area .footer-bottom {
    margin-top: 70px
}

@media (max-width: 991px) {
    .footer-area .footer-bottom {
        margin-top: 20px
    }
}

.footer-area .footer-bottom p {
    color: #797979
}

.footer-area .footer-bottom p a {
    color: #fff
}

.footer-area .footer-bottom .footer-social {
    text-align: center
}

@media (max-width: 991px) {
    .footer-area .footer-bottom .footer-social {
        text-align: left;
        margin-top: 30px
    }
}

.footer-area .footer-bottom .footer-social a {
    background: rgba(255, 255, 255, 0.15);
    margin-left: 3px;
    width: 32px;
    color: #fff;
    display: inline-grid;
    text-align: center;
    height: 32px;
    align-content: center
}

.footer-area .footer-bottom .footer-social a:hover {
    background: #fff;
    color: #21146a
}

.footer-area .footer-bottom .footer-social a i, .footer-area .footer-bottom .footer-social a span {
    font-size: 14px
}

.container > .navbar-header, .container-fluid > .navbar-header, .container > .navbar-collapse, .container-fluid > .navbar-collapse{
    margin-right:0;
    margin-left:0

}
