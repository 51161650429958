@-webkit-keyframes anim {
  0% {
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
@-moz-keyframes anim {
  0% {
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
@-ms-keyframes anim {
  0% {
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
@-o-keyframes anim {
  0% {
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
@keyframes anim {
  0% {
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
.modal-open .section {
  -webkit-filter: blur(30px);
  -ms-filter: blur(30px);
  -o-filter: blur(30px);
  filter: blur(30px); }

.fade.in {
  background: rgba(255, 255, 255, 0.1); }

.modal {
  position: relative;
  background: rgba(255, 255, 255, 0.1); }
  .modal .modal-content {
    height: 100%;
    border-radius: 0px;
    border: none;
    box-shadow: 0 6px 24px 0 rgba(0, 0, 0, 0.24);
    background-color: #ffffff;
    padding: 45px;
    padding-bottom: 0px; }
  .modal .modal-dialog {
    margin: 0px;
    width: 100% !important;
    height: 100% !important; }
    .modal .modal-dialog .vAlignMid {
      margin-top: 0px !important;
      margin-bottom: 0px !important; }
  .modal .clBtn {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 22px;
    height: 22px; }
  .modal .modal-body {
    margin-top: 30px; }
  .modal .w-button {
    margin-top: 30px;
    padding: 5px 15px;
    font-family: os;
    font-weight: 600;
    font-size: 12px; }
    .modal .w-button:hover {
      background: #fff;
      border: 2px solid #9b9b9b; }
      .modal .w-button:hover a {
        color: #9b9b9b; }
  .modal .g-button {
    margin-top: 30px;
    padding: 5px 15px;
    font-family: os;
    font-weight: 600;
    font-size: 12px;
    border: 2px solid #00d48c; }
    .modal .g-button:hover {
      border: 2px solid #01C281; }
  .modal .modal-header {
    border: none; }
  .modal .vAlignMid {
    margin-bottom: 0px; }
    .modal .vAlignMid .modalimg {
      margin-top: 115px; }
  .modal .modal-body {
    padding: 0px; }
  .modal .b-fb-button {
    margin-top: 30px; }

.modalimg {
  position: absolute;
  bottom: 0px;
  width: 75%;
  left: 12.5%; }

.modalHeader {
  font-family: os;
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.37;
  letter-spacing: normal;
  text-align: center;
  color: #616161; }

.modalPar {
  margin-top: 30px;
  font-family: os;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.93;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b; }
  .modalPar sgtrong {
    font-family: ossbold;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: normal;
    text-align: center;
    color: #3b3b3b; }

@media screen and (max-width: 767px) {
  .modalHeader {
    font-size: 23px; }

  .modalPar {
    font-size: 12px; }

  .modal .hp-button {
    width: 100%;
    margin-top: 15px;
    text-align: center;
    margin-right: 0px !important; } }
@media screen and (max-width: 1025px) {
  .modal-content {
    width: 100% !important; } }

/*# sourceMappingURL=modal.css.map */
