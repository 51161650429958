@-webkit-keyframes anim {
  0% {
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
@-moz-keyframes anim {
  0% {
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
@-ms-keyframes anim {
  0% {
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
@-o-keyframes anim {
  0% {
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }
@keyframes anim {
  0% {
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg); }
  100% {
    -webkit-transform: rotateZ(360deg);
    -moz-transform: rotateZ(360deg);
    -ms-transform: rotateZ(360deg);
    -o-transform: rotateZ(360deg);
    transform: rotateZ(360deg); } }

.ffamilybiko {
  font-family: biko; }

.ffamilynunito {
  font-family: nunito; }

.ffamilyos {
  font-family: os; }

.ffamilyossbold {
  font-family: ossbold; }

.ffamilyosbold {
  font-family: osbold; }

.ffamilyoslight {
  font-family: oslight; }

.ffamilyroboto {
  font-family: roboto; }

html, body {
  margin: 0px;
  padding: 0px; }

body {
  /*cursor: url("../img/tekerlek.svg");*/
  overflow-x: hidden; }

.title{
  color: #fff;
  font: 900 36px "Open Sans", sans-serif;
  letter-spacing: -0.2px;
}

.sub-title{
  color: #333;
  font: 900 36px "Open Sans", sans-serif;
  letter-spacing: -0.2px;
}


.btn-solid-lg {
  border-color:#fff;
  background-color: #22195b;
  color: #fff!important;
}

.btn-solid-lg:hover {
  background-color: #fff;
  color: #22195b!important;
  text-decoration: none;
}


.btn-solid-lg-sign {
  border-color:#42E8E0;
  background-color: #42E8E0;
  color: #22195b!important;
}

.btn-solid-lg-sign:hover {
  background-color: #fff;
  color: #22195b!important;
  text-decoration: none;
}

.bg-title{
  margin-bottom: 1rem;
  color: #fff;

  font: 700 3rem "Open Sans", sans-serif;
  letter-spacing: -0.2px;

}

.p-large {
  font: 400 1.125rem/1.75rem "Open Sans", sans-serif;
  margin-bottom: 2rem;
  color: #f3f7fd;

}

.text-container {
  margin-bottom: 3rem;
}


a, ::before, .anim, .g-i-button input, .g-button, .w-button:hover, .k-button:hover, .y-button, .y-button:hover, .b-fb-button, .custom-select .checkmark, .pricing .pric-inner:hover .w-button, .pricing .w-button:hover {
  transition: all 0.4s linear;
  -webkit-transition: all 0.4s linear;
  -moz-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  -ms-transition: all 0.4s linear; }

a:hover, a:focus {
  text-decoration: none !important;
  outline: none !important; }

.no-padding {
  padding: 0 !important; }

.no-margin {
  margin: 0 !important; }

.no-background {
  background: transparent !important; }

.no-border {
  border: none !important; }

.no-box-shadow {
  box-shadow: none !important; }

.no-list-style-type {
  list-style-type: none !important; }

.no-float {
  float: none !important; }

.no-display {
  display: none !important; }

.flLeft {
  float: left !important; }

.flRight {
  float: right !important; }

.lspacing {
  letter-spacing: normal; }

.w100flLeft {
  width: 100%;
  float: left; }

.w50center {
  width: 50%;
  margin-left: auto;
  margin-right: auto; }

.h100, html, body {
  height: 100% !important; }

.vAlignWr {
  display: table;
  height: 100%;
  width: 100%; }

.vAlign {
  vertical-align: middle;
  display: table-cell;
  width: 100%; }

.relative {
  position: relative !important; }

.absolute {
  position: absolute !important; }

.fs60 {
  font-size: 60px; }

.fs40 {
  font-size: 2.5em; }

.fs36 {
  font-size: 36px; }

.fs30 {
  font-size: 1.875em; }

.fs25 {
  font-size: 1.5625em; }

.fs24 {
  font-size: 24px; }

.fs23 {
  font-size: 1.4375em; }

.fs20 {
  font-size: 20px; }

.fs18 {
  font-size: 18px; }

.fs16 {
  font-size: 1em; }

.fs14 {
  font-size: 0.875em; }

.fs12 {
  font-size: 0.75em; }

.fs10 {
  font-size: 0.625em; }

.fs8 {
  font-size: 8px; }

.fwbold {
  font-weight: bold; }

.fwbolder {
  font-weight: bolder; }

.fw500 {
  font-weight: 500; }

.fwnormal {
  font-weight: normal; }

.fw600 {
  font-weight: 600; }

.fw800 {
  font-weight: 800; }

.fwlight {
  font-weight: lighter; }

.ta-center {
  text-align: center; }

.mleft15 {
  margin-left: 15px; }

.mright15 {
  margin-right: 15px; }

.mtop15 {
  margin-top: 15px; }

.mbottom15 {
  margin-bottom: 15px; }

.mtopbottom15 {
  margin-top: 15px;
  margin-bottom: 15px; }

.mleftright15 {
  margin-left: 15px;
  margin-right: 15px; }

.mfull15 {
  margin: 15px; }

.mleft30 {
  margin-left: 30px; }

.mright30 {
  margin-right: 30px; }

.mtop30 {
  margin-top: 30px; }

.mbottom30 {
  margin-bottom: 30px; }

.mtopbottom30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.mleftright30 {
  margin-left: 30px;
  margin-right: 30px; }

.mfull30 {
  margin: 30px; }

.mleft60 {
  margin-left: 60px; }

.mright60 {
  margin-right: 60px; }

.mtop60 {
  margin-top: 60px; }

.mbottom60 {
  margin-bottom: 60px; }

.mtopbottom60 {
  margin-top: 60px;
  margin-bottom: 60px; }

.mleftright60 {
  margin-left: 60px;
  margin-right: 60px; }

.mfull60 {
  margin: 60px; }

.brfull {
  -webkit-border-radius: 1000px;
  -moz-border-radius: 1000px;
  -ms-border-radius: 1000px;
  -o-border-radius: 1000px;
  border-radius: 1000px; }

.pleft15 {
  padding-left: 15px; }

.pright15 {
  padding-right: 15px; }

.ptop15 {
  padding-top: 15px; }

.pbottom15 {
  padding-bottom: 15px; }

.ptopbottom15 {
  padding-top: 15px;
  padding-bottom: 15px; }

.pleftright15 {
  padding-left: 15px;
  padding-right: 15px; }

.pfull15 {
  padding: 15px; }

.pleft30 {
  padding-left: 30px; }

.pright30 {
  padding-right: 30px; }

.ptop30 {
  padding-top: 30px; }

.pbottom30 {
  padding-bottom: 30px; }

.ptopbottom30 {
  padding-top: 30px;
  padding-bottom: 30px; }

.pleftright30 {
  padding-left: 30px;
  padding-right: 30px; }

.pfull30 {
  padding: 30px; }

.pleft60 {
  padding-left: 60px; }

.pright60 {
  padding-right: 60px; }

.ptop60 {
  padding-top: 60px; }

.pbottom60 {
  padding-bottom: 60px; }

.ptopbottom60 {
  padding-top: 60px;
  padding-bottom: 60px; }

.pleftright60 {
  padding-left: 60px;
  padding-right: 60px; }

.pfull60 {
  padding: 60px; }

.ptop90 {
  padding-top: 90px; }

.ptop120 {
  padding-top: 120px; }

.pbottom120 {
  padding-bottom: 120px; }

.pleftright75k {
  padding-left: 7.5px !important;
  padding-right: 7.5px !important; }

.line-top {
  width: 100%;
  height: 1px;
  background: #e2e2e2; }

.o-inner-text-link {
  color: #f9ab98; }
  .o-inner-text-link:hover {
    text-decoration: underline !important;
    color: #f9ab98; }

.colorgray {
  color: #616161; }

.colordgray {
  color: #3b3b3b; }

.colorred {
  color: #fe0e0e; }

.splitLineAnim {
  position: relative; }
  .splitLineAnim::before {
    content: "";
    top: 100%;
    left: 0px;
    position: absolute;
    width: 0%;
    height: 2px;
    background: #3b3b3b; }
  .splitLineAnim:hover::before {
    width: 100%; }

.splitTextAnim {
  overflow: hidden;
  position: relative; }
  .splitTextAnim::before {
    content: attr(data-hover);
    top: 0px;
    left: 0px;
    position: absolute;
    width: 0%;
    overflow: hidden;
    height: 100%; }
  .splitTextAnim:hover::before {
    width: 100%; }

.vAlignMid {
  display: table;
  margin: 30 auto; }

.g-dotted-bbutton .g-button::before {
  content: "";
  height: 50px;
  position: absolute;
  left: 50%;
  right: 50%;
  top: 50px;
  border-left: 3px dotted #e8e8e8; }
.g-dotted-bbutton strong {
  font-family: ossbold; }
.g-dotted-bbutton .midText {
  margin-top: 60px; }

.g-dotted-bbutton-right {
  position: relative; }
  .g-dotted-bbutton-right .g-button::before {
    content: "";
    width: 50px;
    position: absolute;
    left: 175;
    bottom: 50%;
    top: 50%;
    border-top: 3px dotted #e8e8e8;
    float: left; }
  .g-dotted-bbutton-right .midText {
    position: absolute;
    left: 250px;
    top: -15px; }

.g-i-button input {
  border-radius: 3px;
  font-size: 0.875em;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  font-family: os;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #00d48c;
  padding: 11px 30px;
  display: initial;
  outline: none;
  border: none;
  text-align: center;
  color: #fff;
  border: 1px solid #00d48c; }
  .g-i-button input:focus {
    border: none; }
  .g-i-button input:hover {
    background: #01C281;
    color: #fff;
    border: 1px solid #01C281; }

.g-list ul {
  margin-bottom: 30px; }
  .g-list ul li {
    line-height: 1.92;
    list-style-position: 10px; }
    .g-list ul li strong {
      font-weight: bold; }
    .g-list ul li i {
      color: #00d48c;
      margin-right: 5px; }

.g-button {
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #00d48c;
  padding: 10px 30px;
  display: initial; }
  .g-button:hover {
    background: #01C281; }
    .g-button:hover a {
      color: #fff; }
  .g-button a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    font-family: ossbold; }

.w-button {
  border: 2px solid #9b9b9b;
  border-radius: 5px;
  padding: 10px 30px; }
  .w-button:hover {
    background: #00d48c;
    border: 2px solid #00d48c; }
    .w-button:hover a {
      color: #fff; }
  .w-button a {
    color: #9b9b9b;
    text-decoration: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    display: initial; }

.k-button {
  border: 1px solid #754d33;
  border-radius: 5px;
  color: #754d33;
  text-decoration: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  font-family: ossbold;
  padding: 10px;
  display: initial; }
  .k-button:hover {
    background: #fff; }
    .k-button:hover a {
      color: #754d33; }
  .k-button a {
    color: #754d33;
    text-decoration: none; }

.y-button {
  border: 1px solid #ffcd04;
  border-radius: 1000px;
  color: #ffffff;
  text-decoration: none;
  font-size: 0.875em;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  font-family: osbold;
  padding: 10px 30px;
  display: initial;
  background-color: #ffcd04;
  padding: 10px 30px;
  display: initial; }
  .y-button:hover {
    background: #ffffff; }
    .y-button:hover a {
      color: #ffcd04; }
  .y-button a {
    color: #ffffff;
    text-decoration: none;
    font-family: osbold; }

.b-fb-button {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #4a6ab9;
  padding: 5px 11px;
  font-size: 0.750em;
  font-family: os;
  border-radius: 3px; }
  .b-fb-button:hover {
    background: #405ca0; }
  .b-fb-button a {
    color: #fff; }
    .b-fb-button a span {
      width: 20px;
      height: 20px;
      text-align: center;
      display: inline-block;
      background: #fff;
      -webkit-border-radius: 100px;
      -moz-border-radius: 100px;
      -ms-border-radius: 100px;
      -o-border-radius: 100px;
      border-radius: 100px;
      margin-right: 10px; }
      .b-fb-button a span i {
        color: #4a6ab9;
        line-height: 20px; }

.signup-cont {
  padding-top: 150px; }
  .signup-cont .vAlignMid {
    margin-top: 0px;
    margin-bottom: 0px; }

.g-checkbox {
  display: flex; }
  .g-checkbox label {
    display: block;
    position: relative;
    padding-left: 15px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
    .g-checkbox label:hover {
      cursor: pointer; }
    .g-checkbox label div {
      width: 100%;
      height: 100%;
      background: #fff; }
  .g-checkbox i {
    margin-left: 10px;
    color: #9b9b9b;
    font-family: os;
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left; }

.iDesign {
  outline: none;
  padding: 10px;
  width: 100%;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid #aaa9a9;
  color: #434343;
  font-family: nunito;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #434343; }
  .iDesign::-webkit-input-placeholder {
    color: #c4c4c4; }
  .iDesign::-moz-placeholder {
    color: #c4c4c4; }
  .iDesign::-ms-input-placeholder {
    color: #c4c4c4; }

.selsearch {
  padding: 15px;
  border: 1px solid #aaa9a9;
  display: inherit;
  margin-top: 15px;
  border-radius: 6px 0px 0px 6px;
  position: relative; }
  .selsearch span {
    margin-right: 15px;
    margin-left: 5px;
    font-size: 0.6875em;
    font-family: os;
    color: #a2a2a2; }
  .selsearch i {
    color: #fff; }
  .selsearch .s-inner {
    padding: 7px;
    top: 50px;
    left: 0px;
    border: 1px solid #e2e2e2; }
    .selsearch .s-inner i {
      top: 15px;
      left: 12px;
      color: #e2e2e2; }
    .selsearch .s-inner input {
      outline: none;
      padding: 5px 5px 5px 20px;
      width: 100%;
      -webkit-border-radius: 2px;
      -moz-border-radius: 2px;
      -ms-border-radius: 2px;
      -o-border-radius: 2px;
      border-radius: 2px;
      border: 1px solid #aaa9a9;
      color: #434343;
      border: 1px solid #e2e2e2; }
      .selsearch .s-inner input::-webkit-input-placeholder {
        color: #c4c4c4; }
      .selsearch .s-inner input::-moz-placeholder {
        color: #c4c4c4; }
      .selsearch .s-inner input::-ms-input-placeholder {
        color: #c4c4c4; }
  .selsearch ul li {
    list-style-type: none;
    margin-top: 10px;
    span: first-child; }

@-webkit-keyframes menu-anim {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); } }
@-moz-keyframes menu-anim {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); } }
@-ms-keyframes menu-anim {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); } }
@-o-keyframes menu-anim {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); } }
@keyframes menu-anim {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-10px);
    -moz-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    -o-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px); } }
.live-sup {
  float: right;
  margin-top: 10px;
  display: none; }
  .live-sup a {
    padding: 5px;
    display: block;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    -ms-border-radius: 100px;
    -o-border-radius: 100px;
    border-radius: 100px; }

.header {
  width: 100%;
  height: 91px;
  padding-bottom: 30px;
  position: absolute;
  background-color: #5f4def;
  top: 0px;
  left: 0px;
  /*background: #faf6eb;*/
  z-index: 1; }
  .header .prpolicy-menu {
    position: absolute;
    bottom: 30px;
    text-align: center;
    width: 100%;
    display: none; }
    .header .prpolicy-menu a {
      text-align: center;
      font-family: osbold;
      text-decoration: none;
      font-size: 11px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.15;
      letter-spacing: normal;
      color: #878787;
      margin-right: 15px; }
      .header .prpolicy-menu a:last-child {
        margin-right: 0px; }
  .header:hover {
    background-color: #5f4def;
  }
  .header .logo {
    float: left;
    padding-top: 30px; }
    .header .logo img {
      height: 30px;
      float: left;
      margin-right: 5px; }
  .header .hambmenu {
    width: 30px;
    height: 45px;
    padding: 5px;
    margin-top: 25px;
    margin-left: 200px;
    display: none;
    float: left; }
    .header .hambmenu .line {
      width: 100%;
      height: 3px;
      background: #fff;
      margin-top: 4px; }
  .header .menu {
    float: right; }
    .header .menu .closeArea {
      width: 100%;
      display: none; }
      .header .menu .closeArea .closeBtn2 {
        width: 40px;
        height: 40px;
        padding: 5px;
        margin-top: 0px;
        margin-bottom: 25px; }
    .header .menu .login-b {
      border: 1px solid #fff;
      border-radius: 5px;
      padding: 4.5px 17.4px;
      font-family: osbold;
      position: relative;
      margin-top: 27px;
      text-decoration: none;
      font-size: 11px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.15;
      letter-spacing: normal;
      text-align: right;
      margin-left: 10px; }
      .header .menu .login-b:hover {
        background: #fff; }
        .header .menu .login-b:hover a {
          color: #fff; }
      .header .menu .login-b a {
        color: #fff; }
    .header .menu .tasi-ac-u:hover {
      cursor: pointer; }
      .header .menu .tasi-ac-u:hover .u-acilir {
        display: block; }
    .header .menu .login-u {
      margin-left: 15px;
      height: 100%;
      padding-top: 30px;
      height: 91px;
      margin-top: -4px;
      float: left; }
      .header .menu .login-u .user-img {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.26);
        float: left; }
      .header .menu .login-u .img-text {
        font-family: osbold;
        font-size: 11px;
        font-weight: 500;
        line-height: 30px;
        margin-left: 10px;
        float: left; }
        .header .menu .login-u .img-text i {
          margin-left: 5px;
          font-weight: 600; }
      .header .menu .login-u .u-acilir {
        position: absolute;
        top: 75px;
        width: 225px;
        background: #fff;
        z-index: 9999;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        right: 0px;
        display: none;
        -webkit-animation: menu-anim 0.3s 0s linear forwards;
        -moz-animation: menu-anim 0.3s 0s linear forwards;
        -ms-animation: menu-anim 0.3s 0s linear forwards;
        -o-animation: menu-anim 0.3s 0s linear forwards;
        animation: menu-anim 0.3s 0s linear forwards; }
        .header .menu .login-u .u-acilir ul {
          padding: 0px !important;
          margin: 0px; }
          .header .menu .login-u .u-acilir ul li {
            list-style-type: none;
            padding: 11.2px 20px;
            border-bottom: 1px solid #f2f2f2; }
            .header .menu .login-u .u-acilir ul li:hover {
              background: #faf6eb; }
            .header .menu .login-u .u-acilir ul li .flRight {
              color: #878787;
              font-family: roboto;
              font-weight: normal;
              font-size: 0.875em; }
    .header .menu .tasi-ac:hover .acilir-inner {
      display: block; }
    .header .menu .tasi-ac:hover a {
      color: #fff; }
      .header .menu .tasi-ac:hover a i {
        color: #fff;
        -webkit-transform: rotateZ(180deg);
        -moz-transform: rotateZ(180deg);
        -ms-transform: rotateZ(180deg);
        -o-transform: rotateZ(180deg);
        transform: rotateZ(180deg); }
    .header .menu .acilir .acilir-inner {
      width: 100%;
      position: absolute;
      height: auto;
      display: none;
      opacity: 0;
      margin-top: 30px;
      background: #fff;
      left: 0px;
      z-index: 9999;
      overflow: hidden;
      box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.16);
      -webkit-animation: menu-anim 0.3s 0s linear forwards;
      -moz-animation: menu-anim 0.3s 0s linear forwards;
      -ms-animation: menu-anim 0.3s 0s linear forwards;
      -o-animation: menu-anim 0.3s 0s linear forwards;
      animation: menu-anim 0.3s 0s linear forwards; }
      .header .menu .acilir .acilir-inner ul {
        float: right;
        margin: 30px 0px; }
        .header .menu .acilir .acilir-inner ul li {
          margin-left: 20px;
          list-style-type: none;
          float: left;
          width: 158px;
          height: 150px;
          text-align: center;
          border-radius: 10px;
          border: solid 1px #ececec;
          padding: 10px;
          background: #fff;
          font-family: osbold; }
          .header .menu .acilir .acilir-inner ul li:nth-child(2) img {
            width: 100px; }
          .header .menu .acilir .acilir-inner ul li:nth-child(3) img {
            width: 75px; }
          .header .menu .acilir .acilir-inner ul li:hover {
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            border: solid 1px #f2f2f2;
            background-color: #fcfcfc; }
            .header .menu .acilir .acilir-inner ul li:hover a {
              color: #fff; }
          .header .menu .acilir .acilir-inner ul li a {
            margin-top: 10px;
            color: #fff; }
          .header .menu .acilir .acilir-inner ul li img {
            height: 75%;
            margin-bottom: 10px;
            margin-left: auto;
            margin-right: auto; }
    .header .menu .activeLink {
      color: #fff; }
    .header .menu ul {
      padding: 0px; }
      .header .menu ul .menu-list {
        float: left;
        list-style-type: none;
        height: 91px;
        padding: 30px 15px; }
        .header .menu ul .menu-list a {
          position: relative;
          text-decoration: none;
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.15;
          letter-spacing: normal;
          text-align: right;
          color: #fff; }
          .header .menu ul .menu-list a:hover {
            color: #fff; }
          .header .menu ul .menu-list a i {
            color: #fff;
            margin-left: 2px; }
  .header .langMenu {
    float: left;
    margin-left: 15px;
    padding-bottom: 30px;
    margin-top: 27px; }
    .header .langMenu .lang-inner {
      float: left;
      position: relative;
      padding: 4.5px 10px;
      border: 1px solid #acacac;
      border-radius: 5px; }
      .header .langMenu .lang-inner a {
        color: #fff; }
        .header .langMenu .lang-inner a i {
          font-weight: 600;
          margin-left: 5px; }
      .header .langMenu .lang-inner img {
        width: 25px;
        height: 15px;
        margin-top: -2px; }
      .header .langMenu .lang-inner span {
        font-family: osbold;
        position: relative;
        text-decoration: none;
        font-size: 11px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.15;
        letter-spacing: normal;
        text-align: right;
        color: #fff; }
      .header .langMenu .lang-inner ul {
        background: #fff;
        position: absolute;
        display: none;
        -webkit-animation: menu-anim 0.3s 0s linear forwards;
        -moz-animation: menu-anim 0.3s 0s linear forwards;
        -ms-animation: menu-anim 0.3s 0s linear forwards;
        -o-animation: menu-anim 0.3s 0s linear forwards;
        animation: menu-anim 0.3s 0s linear forwards;
        top: 25px;
        right: 0px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16); }
        .header .langMenu .lang-inner ul li {
          list-style-type: none;
          border-bottom: 1px solid #f2f2f2; }
          .header .langMenu .lang-inner ul li a {
            display: block;
            clear: both;
            font-weight: normal;
            line-height: 1.42857143;
            color: #333;
            white-space: nowrap;
            padding: 11.2px 16.5px;
            background: #fff; }
            .header .langMenu .lang-inner ul li a:hover {
              background: #faf6eb; }
    .header .langMenu:hover ul {
      display: block; }
  .header .socialMenu {
    width: 100%;
    display: none;
    float: none; }
    .header .socialMenu .socialMenu-inner {
      display: table;
      margin: 0 auto;
      position: relative; }
      .header .socialMenu .socialMenu-inner ul {
        display: table;
        margin: 0 auto; }
        .header .socialMenu .socialMenu-inner ul li {
          list-style-type: none;
          float: left;
          margin-left: 15px; }
          .header .socialMenu .socialMenu-inner ul li:nth-child(1) {
            margin-left: 7px; }
            .header .socialMenu .socialMenu-inner ul li:nth-child(1) a {
              color: #3b5998; }
            .header .socialMenu .socialMenu-inner ul li:nth-child(1) i {
              font-size: 36px; }
          .header .socialMenu .socialMenu-inner ul li:nth-child(2) a {
            color: #1da1f2; }
          .header .socialMenu .socialMenu-inner ul li:nth-child(2) i {
            font-size: 36px; }
          .header .socialMenu .socialMenu-inner ul li:nth-child(3) a {
            color: #fff; }
          .header .socialMenu .socialMenu-inner ul li:last-child {
            width: 31px;
            height: 31px;
            margin-top: 0px;
            background: #66cdaa;
            text-align: center;
            border-radius: 5px; }
          .header .socialMenu .socialMenu-inner ul li i {
            font-size: 20px;
            line-height: 30px;
            text-align: center; }

.slider {
  width: 100%;
  background-color: #5f4def;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 50%;
  padding-top: 110px;
  padding-bottom: 30px; }
  .slider .g-dotted-bbutton {
    margin-top: 12px; }
    .slider .g-dotted-bbutton .g-button {
      margin-left: 15px; }
  .slider .g-button {
    padding: 12px 30px; }
  .slider .slTextSet {
    margin: 0px; }
  .slider .w-button:hover {
    background: #fff;
    border: 2px solid #9b9b9b; }
    .slider .w-button:hover a {
      color: #9b9b9b; }
  .slider .slImgText p {
    bottom: 15px;
    right: 0px;
    font-family: os;
    font-weight: normal;
    font-size: 1em;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #fff; }
    .slider .slImgText p .slImgText img {
      margin-left: auto;
      margin-right: auto; }
  .slider h2 {
    font-size: 6.25em;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    font-family: osbold; }
  .slider h5 {
    font-size: 3.33em;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    font-family: osbold; }
  .slider h1 {
    font-size: 30px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    font-family: os; }
    .slider h1 strong {
      font-size: 45px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
      font-family: osbold;
      display: block !important;
      margin-bottom: 10px; }
  .slider h4 {
    font-size: 1.875em;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    font-family: os; }
  .slider .slText {
    font-family: os;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-bottom: 20px; }
  .slider .g-list ul {
    padding: 0px;
    margin-bottom: 30px; }
    .slider .g-list ul li {
      list-style-type: none;
      font-family: os;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      font-family: os;
      line-height: 1.7;
      font-size: 12px; }
      .slider .g-list ul li strong {
        font-weight: bold; }
      .slider .g-list ul li i {
        color: #00d48c;
        margin-right: 5px; }
  .slider .slimg {
    width: 100%;
    margin: 0 auto; }
  .slider .slImgText p {
    text-align: center !important;
    font-family: os;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #fff; }
  .slider .slImgText .vAlignMid {
    margin: 0px auto; }

.slider-lg {
  width: 100%;
  /*background: url(../img/sbg.svg) #faf6eb;*/
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 50%;
  padding-top: 110px;
  padding-bottom: 30px; }
  .slider-lg h1 {
    font-family: os;
    font-size: 36px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.19;
    letter-spacing: normal;
    text-align: left;
    color: #fff; }
    .slider-lg h1 strong {
      font-weight: 500;
      font-size: 70px;
      display: block;
      font-family: osbold; }
  .slider-lg .slText {
    font-family: os;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    margin-bottom: 20px; }
  .slider-lg .g-list ul {
    padding: 0px;
    margin-bottom: 30px; }
    .slider-lg .g-list ul li {
      list-style-type: none;
      font-family: os;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: normal;
      font-family: os;
      line-height: 1.7;
      font-size: 12px; }
      .slider-lg .g-list ul li strong {
        font-weight: bold; }
      .slider-lg .g-list ul li i {
        color: #00d48c;
        margin-right: 5px; }
  .slider-lg .w-button {
    padding: 8px 30px;
    margin-top: 0px; }
  .slider-lg .w-button:hover {
    background: #fff;
    border: 2px solid #9b9b9b; }
    .slider-lg .w-button:hover a {
      color: #9b9b9b; }

.custom-select {
  display: block;
  position: relative;
  padding-left: 10px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }
  .custom-select input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0; }
    .custom-select input:checked ~ .checkmark {
      background: #00d48c; }
  .custom-select .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    margin-right: 5px;
    border: 1px solid #e2e2e2;
    padding: 1px; }

.works {
  width: 100%;
  background: #fff;
  padding: 50px 0px; }
  .works .works-header {
    font-family: osbold;
    font-size: 2.5em;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #fff; }


.features {
  width: 100%;
  /*background: url(../img/midbg.svg) #414141 no-repeat;*/
  background-position: 0% 100%;
  padding: 100px 0px;
  color: #ffffff; }
  .features .workspresp {
    font-family: os;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    width: 52.5%;
    margin-right: auto;
    margin-left: auto; }
  .features .f-header {
    font-family: osbold;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    text-align: center; }
  .features h3 {
    font-family: osbold;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #ffcd04; }
  .features p {
    font-family: os;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff; }
  .features .fBaslik {
    color: #ffcd04; }
  .features .fList {
    padding: 0px 60px; }
  .features img {
    width: 81px;
    height: 81px; }

.mplatform {
  width: 100%;
  background: #fff;
  padding: 50px 0px;
  color: #3b3b3b; }
  .mplatform .mplatform-header {
    font-family: osbold;
    font-size: 2.5em;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #3b3b3b; }
  .mplatform .workspresp {
    color: #3b3b3b;
    font-family: os;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    width: 52.5%;
    margin-right: auto;
    margin-left: auto;
    text-align: center; }
  .mplatform h3 {
    font-family: osbold;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #3b3b3b;
    margin-bottom: 15px !important; }
  .mplatform p {
    font-family: os;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #3b3b3b; }
  .mplatform .pInfo:last-child {
    margin-top: 70px; }
  .mplatform .g-button::before {
    content: "";
    height: 50px;
    position: absolute;
    left: 50%;
    right: 50%;
    top: 50px;
    border-left: 3px dotted #e8e8e8; }
  .mplatform .midText {
    margin-top: 60px; }
  .mplatform .g-button {
    font-family: oslight;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: normal; }
  .mplatform .midText i {
    font-family: os;
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #754d33; }
    .mplatform .midText i strong {
      font-family: os;
      font-size: 12px;
      font-weight: 600;
      font-style: italic;
      font-stretch: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: center;
      color: #754d33 !important; }

.question {
  width: 100%;
  background: #faf6eb;
  padding: 50px 0px;
  color: #3b3b3b; }
  .question .q-header {
    font-family: osbold;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #3b3b3b;
    text-align: center; }
  .question .q-mid-p {
    color: #3b3b3b;
    font-family: os;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    margin-right: auto;
    margin-left: auto;
    text-align: center; }
  .question .k-button {
    padding-left: 40px; }
    .question .k-button img {
      position: absolute;
      left: 10px;
      top: 7px; }
    .question .k-button img:nth-child(2) {
      display: none; }

.pricing {
  width: 100%;
  background: #ffffff;
  padding: 50px 0px;
  color: #3b3b3b; }
  .pricing .p-header {
    font-family: osbold;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #3b3b3b;
    text-align: center; }
  .pricing .workspresp {
    color: #3b3b3b;
    font-family: os;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    width: 52.5%;
    margin-right: auto;
    margin-left: auto;
    text-align: center; }
  .pricing .pric-inner:hover {
    background: #fbfbfb; }
    .pricing .pric-inner:hover .w-button {
      background: #00d48c;
      border: 1px solid #00d48c; }
      .pricing .pric-inner:hover .w-button a {
        color: #fff; }
  .pricing .active-y {
    background: #ffcd04;
    border: 1px solid #ffcd04 !important; }
    .pricing .active-y a {
      color: #fff !important; }
    .pricing .active-y:hover {
      background: #ecbe05;
      border: 1px solid #ffcd04 !important; }
  .pricing .w-button {
    border: 1px solid #707070;
    border-radius: 100px !important; }
    .pricing .w-button:hover {
      background: #ecbe05;
      border: 1px solid #ffcd04 !important;
      cursor: pointer; }
      .pricing .w-button:hover a {
        color: #fff; }
    .pricing .w-button a {
      color: #fff;
      font-family: osbold;
      font-family: osbold;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.17;
      letter-spacing: normal;
      color: #3b3b3b; }
  .pricing .prcBody .w-button {
    font-family: osbold !important;
    border: 1px solid #707070;
    border-radius: 5px !important;
    display: inline-block;
    padding: 10px 20px; }
    .pricing .prcBody .w-button a {
      font-family: os;
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.36;
      letter-spacing: normal;
      color: #3b3b3b; }
  .pricing .pric-inner h3 {
    font-family: osbold;
    font-size: 25px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #3b3b3b; }
  .pricing .pric-inner p {
    font-family: os;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #616161; }
  .pricing .pric-inner .prcBody .prcBodyMon {
    font-family: osbold;
    font-size: 60px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #616161; }
  .pricing .pric-inner .prcBody i {
    font-family: oslight;
    font-size: 11px;
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.82;
    letter-spacing: normal;
    text-align: left;
    color: #3b3b3b; }
  .pricing .pric-inner li {
    font-family: os;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.17;
    letter-spacing: normal;
    text-align: left;
    color: #616161; }

.footer {
  width: 100%;
  background: #414141;
  padding-top: 60px;
  color: #3b3b3b;
  position: relative; }
  .footer .prpolicy-footer {
    position: absolute;
    bottom: 15px;
    left: 15px; }
    .footer .prpolicy-footer a {
      font-family: os;
      font-size: 10px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2;
      letter-spacing: normal;
      color: #787878;
      margin-right: 15px; }
      .footer .prpolicy-footer a:last-child {
        margin-right: 0px; }
  .footer h4 {
    font-family: osbold;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-top: 30px; }
  .footer p {
    font-family: os;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #9b9b9b; }
  .footer .socialMenu {
    display: block;
    float: left;
    width: 100%; }
    .footer .socialMenu .socialMenu-inner {
      position: relative;
      margin-bottom: 30px; }
      .footer .socialMenu .socialMenu-inner ul {
        padding-left: 0px; }
        .footer .socialMenu .socialMenu-inner ul li {
          list-style-type: none;
          float: left;
          margin-left: 15px;
          width: 31px;
          height: 31p;
          text-align: center;
          border-radius: 5px;
          background: #fff; }
          .footer .socialMenu .socialMenu-inner ul li a {
            color: #414141; }
            .footer .socialMenu .socialMenu-inner ul li a i {
              font-size: 20px;
              line-height: 30px;
              text-align: center; }
          .footer .socialMenu .socialMenu-inner ul li:nth-child(1) {
            margin-left: 0px; }
            .footer .socialMenu .socialMenu-inner ul li:nth-child(1):hover {
              background: #3b5998; }
          .footer .socialMenu .socialMenu-inner ul li:nth-child(2):hover {
            background: #1da1f2; }
          .footer .socialMenu .socialMenu-inner ul li:nth-child(3):hover {
            background: #66cdaa; }
  .footer .f-c-info {
    font-family: os;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: left;
    color: #9b9b9b; }
    .footer .f-c-info strong {
      font-family: osbold;
      font-size: 12px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #9b9b9b; }
  .footer li {
    margin-top: 5px;
    list-style-type: none; }
    .footer li a {
      color: #9b9b9b; }
      .footer li a:hover {
        color: #f89c61; }
  .footer .copyright {
    font-family: os;
    text-align: center;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    color: #787878; }

.pricing #price-list1 {
  display: none; }

.pricing #price-list2 {
  display: none; }

.inputs {
  padding-top: 90px; }
  .inputs h4 {
    color: #616161;
    margin-top: 60px; }
  .inputs .tsandpp i {
    margin-left: 10px;
    color: #9b9b9b; }
  .inputs .g-button:hover {
    border: 1px solid #00d48c; }

.cPassword .cPassword-header {
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.37;
  letter-spacing: normal;
  text-align: center;
  color: #616161;
  margin-top: 50px;
  margin-bottom: 50px; }
.cPassword input:nth-child(2) {
  margin-top: 15px; }

.loginPage .iDesign {
  margin-top: 10px; }
  .loginPage .iDesign:first-child {
    margin-top: 0px !important; }
.loginPage .forgot-pass {
  float: right;
  margin-top: 15px; }
.loginPage .g-checkbox {
  float: left;
  margin-top: 15px; }
.loginPage a {
  color: #9b9b9b; }
  .loginPage a:hover {
    color: #f9ab98; }
.loginPage .g-i-button {
  margin-top: 50px; }

.pRecovery .g-i-button {
  margin-top: 15px; }

.csociallogo {
  display: inherit;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border-radius: 6px;
  margin-right: 15px; }
  .csociallogo:last-child {
    margin-right: 0px !important; }
  .csociallogo:hover {
    -webkit-transform: translateY(-5px);
    -moz-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px); }
  .csociallogo img {
    width: 100%; }

.signup {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #f2f2f2;
  background-color: #ffffff;
  padding-top: 40px;
  padding-bottom: 40px; }
  .signup .signup-inner {
    padding-left: 60px;
    padding-right: 60px;
    border-right: 1px solid #f2f2f2; }
    .signup .signup-inner .signup-header {
      font-family: os;
      font-size: 30px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.37;
      letter-spacing: normal;
      text-align: left;
      color: #616161; }
    .signup .signup-inner .iDesign {
      margin-top: 15px; }
  .signup .con-with {
    margin-top: 20px;
    display: block;
    font-family: ossbold;
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    color: #616161; }
  .signup .g-i-button {
    margin-top: 15px; }
  .signup .csocial {
    margin-top: 15px; }
  .signup .why-header {
    font-family: os;
    font-size: 20px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #3b3b3b;
    margin-top: 75px; }
  .signup .g-list {
    margin-top: 27px; }
    .signup .g-list ul {
      padding: 0px; }
      .signup .g-list ul li {
        list-style-type: none;
        font-family: os;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.92;
        letter-spacing: normal;
        text-align: left;
        color: #616161;
        margin-top: 21px; }
        .signup .g-list ul li strong {
          font-weight: 600;
          font-family: ossbold; }

.ifLogin1 {
  display: none; }

.ifLogin2 {
  margin: 60px 0px;
  display: block; }
  .ifLogin2 i {
    color: #616161; }

.parPage {
  padding-bottom: 90px; }
  .parPage .pp-header {
    font-family: osbold;
    font-size: 40px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #3b3b3b; }
  .parPage .pp-sub-header {
    font-family: osbold;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #3b3b3b;
    margin-top: 40px;
    margin-bottom: 20px; }
  .parPage .pp-par {
    font-family: os;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #3b3b3b;
    margin-bottom: 40px; }

.mobile-ntf .m-ntf-header {
  font-family: osbold;
  font-size: 28px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b; }
.mobile-ntf .m-ntf-p-lg {
  font-family: osbold;
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.74;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
  margin-top: 30px; }
.mobile-ntf .m-ntf-p-lg:first-child {
  margin-top: 30px !important; }
.mobile-ntf .m-ntf-p {
  font-family: os;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
  margin-top: 30px; }
.mobile-ntf .m-ntf-sub-header {
  font-family: osbold;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
  margin-top: 50px;
  margin-bottom: 0px; }
.mobile-ntf .ntf-inner {
  margin-top: 30px; }
  .mobile-ntf .ntf-inner .ntf-list-text {
    height: 250px;
    padding-left: 120px; }
    .mobile-ntf .ntf-inner .ntf-list-text img {
      left: 0px;
      z-index: -1;
      width: 150px;
      top: 60px; }
    .mobile-ntf .ntf-inner .ntf-list-text h3 {
      font-family: osbold;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.75;
      letter-spacing: normal;
      text-align: left;
      color: #3b3b3b;
      margin-top: 60px; }
    .mobile-ntf .ntf-inner .ntf-list-text p {
      font-family: os;
      font-size: 12px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      text-align: left;
      color: #3b3b3b;
      margin-top: 20px; }
.mobile-ntf .g-button {
  font-family: oslight;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal; }
.mobile-ntf .midText i {
  font-family: os;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #754d33; }
  .mobile-ntf .midText i strong {
    font-family: os;
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #754d33 !important; }

.our-content .cont-header {
  font-family: osbold;
  font-size: 40px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
  margin-top: 50px; }
.our-content .cont-mid-p {
  font-family: os;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #3b3b3b;
  margin-top: 20px;
  width: 42.5%;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 50px; }
.our-content .user-img {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 100px; }
.our-content .socialInfo {
  border-radius: 6px;
  box-shadow: 0 10px 29px 0 rgba(0, 0, 0, 0.16);
  padding: 15px; }
  .our-content .socialInfo h3 {
    font-family: osbold;
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin: 0px; }
  .our-content .socialInfo p {
    font-family: os;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.58;
    letter-spacing: normal;
    text-align: left;
    color: #3d3d3d;
    margin-top: 16.4px; }
  .our-content .socialInfo .user-text i {
    font-family: os;
    font-size: 10px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #bababa; }
  .our-content .socialInfo .user-text span {
    font-family: os;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    text-align: left;
    color: #878787; }
  .our-content .socialInfo .share-list {
    padding: 0px;
    margin: 0px; }
    .our-content .socialInfo .share-list li {
      list-style-type: none;
      float: left;
      width: 27px;
      height: 25px;
      text-align: center;
      margin-right: 2px; }
      .our-content .socialInfo .share-list li .share-icon {
        width: 27px;
        height: 25px; }
        .our-content .socialInfo .share-list li .share-icon i {
          line-height: 25px;
          color: #fff;
          text-align: center; }
      .our-content .socialInfo .share-list li:nth-child(1) {
        background: #45619d; }
      .our-content .socialInfo .share-list li:nth-child(2) {
        background: #55acee; }
      .our-content .socialInfo .share-list li:nth-child(3) {
        background: #d73d32; }
      .our-content .socialInfo .share-list li:nth-child(4) {
        background: #007ab9; }
      .our-content .socialInfo .share-list li:nth-child(5) {
        background: #57bb63; }
  .our-content .socialInfo .share-text {
    float: left;
    font-family: os;
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.1;
    letter-spacing: normal;
    text-align: left;
    color: #2d2d2d;
    margin-right: 13.4px; }

.user-img {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 100px; }

.how-works .g-dotted-bbutton-right {
  margin-top: 30px; }
.how-works .how-works-header {
  font-family: osbold;
  font-size: 30px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
  margin-top: 75px; }
.how-works .how-works-p {
  font-family: os;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #3b3b3b;
  margin-top: 23px; }
.how-works .g-button {
  font-family: oslight;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal; }
.how-works .midText i {
  font-family: os;
  font-size: 12px;
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #754d33; }
  .how-works .midText i strong {
    font-family: os;
    font-size: 12px;
    font-weight: 600;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #754d33 !important; }

.form-header {
  font-family: os;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.37;
  letter-spacing: normal;
  color: #616161;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 15px; }

.form-info {
  font-family: os;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.92;
  letter-spacing: normal;
  color: #616161;
  text-align: center; }

.sure-inp {
  position: relative;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
  .sure-inp #sure {
    font-family: os;
    font-size: 12px;
    font-weight: normal;
    font-style: italic;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: left;
    color: #fe0e0e;
    right: 15px;
    top: 15px; }
  .sure-inp .sure-iDesign {
    outline: none;
    padding: 15px;
    width: 100%;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    -ms-border-radius: 6px;
    -o-border-radius: 6px;
    border-radius: 6px;
    border: 1px solid #aaa9a9;
    color: #434343; }
    .sure-inp .sure-iDesign::-webkit-input-placeholder {
      color: #c4c4c4; }
    .sure-inp .sure-iDesign::-moz-placeholder {
      color: #c4c4c4; }
    .sure-inp .sure-iDesign::-ms-input-placeholder {
      color: #c4c4c4; }

.confGsm #disInp1 {
  padding-right: 50px;
  font-family: nunito;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #c4c4c4; }
.confGsm a {
  color: #9b9b9b; }
.confGsm a:hover {
  color: #fff;
  text-decoration: underline !important;
  padding: 14px 0px; }
.confGsm .g-i-button {
  margin-top: 15px;
  width: 100%;
  margin-left: auto;
  margin-right: auto; }
.confGsm .send-again {
  font-family: os;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: center;
  color: #9b9b9b;
  margin-top: 26px; }

.cAccount .in-conf-sent {
  padding-top: 5px;
  color: #9b9b9b;
  float: right;
  font-family: os;
  font-size: 12px;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #9b9b9b; }
.cAccount .g-checkbox {
  margin-top: 60px;
  margin-bottom: 5px; }

.cPassword .iDesign {
  font-family: nunito;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: left;
  color: #c4c4c4; }
.cPassword .g-i-button {
  font-family: ossbold;
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  margin-top: 30px; }

.prmessage {
  height: 100%; }
  .prmessage .prms-header {
    font-family: ossbold;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: normal;
    text-align: center;
    color: #3b3b3b;
    margin-top: 60px; }
  .prmessage .prms-p {
    font-family: os;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: normal;
    text-align: center;
    color: #3b3b3b; }
  .prmessage .prms-r-p {
    font-family: os;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: normal;
    text-align: center;
    color: #fe0e0e;
    margin-top: 30px; }
  .prmessage .colorred {
    display: none; }
  .prmessage p {
    line-height: 1.93; }

.pushnotfadd {
  padding-top: 191px;
  height: 100%; }
  .pushnotfadd .form-info {
    font-family: ossbold;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.93;
    letter-spacing: normal;
    text-align: center;
    color: #3b3b3b; }

.addapp-tabmenu {
  padding-top: 191px; }
  .addapp-tabmenu .nav-tabs {
    border: none; }
    .addapp-tabmenu .nav-tabs ul li {
      list-style-type: none;
      float: left;
      margin-right: 24px; }
      .addapp-tabmenu .nav-tabs ul li:last-child {
        margin-right: 0px; }

.select2-selection__rendered span img {
  width: 45px;
  height: 30px;
  margin-top: -2px; }

.select2-results__option img {
  width: 40px;
  height: 25px; }

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 3px; }

.gridSelect {
  display: grid;
  grid-template-columns: 25% 75%;
  margin-top: 20px; }
  .gridSelect input {
    border: 1px solid #aaa9a9;
    border-left: none;
    border-radius: 0px 3px 3px 0px;
    outline: none;
    padding: 0px 15px; }

.select2-container--default .select2-results__option[aria-selected=true] {
  background: #f2f2f2; }

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background: #fff; }

.select2-results__option[aria-selected]:hover {
  background: #faf6eb; }

.select2-container--open .select2-dropdown {
  width: 100% !important;
  border: 1px solid #e2e2e2;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  border-radius: 3px;
  margin-top: 5px; }

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #e2e2e2;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  border-radius: 2px;
  font-family: os;
  font-size: 16px;
  position: relative;
  text-indent: 20px; }

.select2-search--dropdown {
  position: relative; }
  .select2-search--dropdown::before {
    content: "\f002";
    font-family: "FontAwesome";
    position: absolute;
    z-index: 9;
    left: 10px;
    top: 15px;
    font-size: 0.750em;
    color: #d5d5d5; }

.select2-search--dropdown input {
  font-size: 0.750em;
  font-family: nunito;
  color: #616161; }
  .select2-search--dropdown input::-webkit-input-placeholder {
    color: #d5d5d5;
    font-family: nunito;
    font-size: 0.750em; }
  .select2-search--dropdown input::-moz-placeholder {
    color: #d5d5d5;
    font-family: nunito;
    font-size: 0.750em; }
  .select2-search--dropdown input::-ms-input-placeholder {
    color: #d5d5d5;
    font-family: nunito;
    font-size: 0.750em; }

.select2-selection__rendered span {
  font-family: os;
  font-size: 13px;
  color: #a2a2a2; }
  .select2-selection__rendered span img {
    margin-right: 0px; }

.select2-results__option img {
  margin-right: 10px; }

.select2-results__option span {
  font-size: 0.6875em;
  font-family: os; }

.select2-results__option span span {
  font-family: os;
  font-size: 12px;
  color: #a2a2a2;
  margin-left: 7.5px; }

.gridSelect .select2.select2-container.select2-container--default, .gridSelect .select2-container--open {
  width: auto !important;
  left: unset !important;
  right: unset !important;
  margin: unset !important; }

.select2-container--open {
  left: 0 !important;
  right: 0 !important;
  margin: 0 auto !important; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 45px; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 45px; }

.select2-container .select2-selection--single {
  height: 45px; }

.select2-search__field, .select2-container .select2-selection--single {
  outline: none; }

.gridSelect .select2-container--default .select2-selection--single {
  border: 1px solid #aaa9a9 !important;
  border-radius: 3px 0 0 3px !important; }

.menu-list-open {
  letter-spacing: normal; }

.login-u-open {
  letter-spacing: normal; }

.menu-tra {
  -webkit-transform: translateX(0%) !important;
  -moz-transform: translateX(0%) !important;
  -ms-transform: translateX(0%) !important;
  -o-transform: translateX(0%) !important;
  transform: translateX(0%) !important; }

.displayM {
  display: block !important; }

@media screen and (max-width: 768px) {
  .gridSelect {
    grid-template-columns: 36% 64%; } }
@media screen and (max-width: 1199px) {
  .gridSelect {
    grid-template-columns: 35% 65%; }

  .form-header {
    font-size: 20px; }

  .form-info {
    font-size: 14px; }

  .slider {
    padding-bottom: 30px; }
    .slider h1 {
      font-size: 28px; }
      .slider h1 strong {
        font-size: 43px; }
    .slider .slText {
      font-size: 16px; }
    .slider li {
      font-size: 12px !important; }
    .slider .w-button a {
      font-size: 12px; }
    .slider .g-button a {
      font-size: 12px; }
    .slider .slImgText p {
      font-size: 14px; }

  .features p {
    font-size: 11px !important; }

  .cont-mid-p {
    width: 52.5% !important; }

  .slider-lg .slText {
    font-size: 15px; } }
@media screen and (max-width: 991px) {
  .prms-header {
    margin-top: 0px !important; }

  .prmessage {
    background-size: 100% 30% !important; }

  .why-header {
    margin-top: 30px !important; }

  .ifLogin1 {
    margin: 0px 0px 30px 0px;
    display: block; }

  .ifLogin2 {
    display: none; }

  .signup .g-list {
    margin-top: 0px; }
    .signup .g-list ul li {
      margin-top: 5px; }

  .signup .csocial {
    display: table;
    margin: 15 auto; }

  .signup-input {
    float: none !important;
    display: table;
    margin: 0 auto; }

  .signup-header {
    text-align: center !important; }

  .signup .con-with {
    text-align: center; }

  .g-i-button.signup-input {
    width: 100%; }

  .signup-cont {
    padding-top: 150px;
    padding-top: 60px; }

  .signup {
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16);
    border: 0px solid #fff; }

  .signup-inner {
    border-right: 0px solid #fff !important; }

  .copyright {
    text-align: right !important; }

  .prpolicy-menu {
    display: block !important; }

  .how-works {
    padding-top: 60px !important; }

  .slider-lg h1 {
    font-size: 28px; }
    .slider-lg h1 strong {
      font-size: 70px; }
  .slider-lg .slText {
    font-size: 13px; }

  .acilir-inner a {
    font-size: 12px !important; }

  .slider h1 {
    font-size: 22px; }
    .slider h1 strong {
      font-size: 33px; }
  .slider .slText {
    font-size: 12px; }
  .slider li {
    font-size: 9px !important; }
  .slider .w-button a {
    font-size: 10px; }
  .slider .g-button a {
    font-size: 10px; }
  .slider .slImgText p {
    font-size: 12px; }

  .works .works-header {
    margin: 0px; }
  .works .works-mid-p {
    width: 70% !important;
    font-size: 16px !important; }

  .form-header {
    font-size: 20px; }

  .form-info {
    font-size: 14px; }

  .features p {
    font-size: 13px !important; }

  .fBaslik {
    margin-bottom: 15px !important; }

  .mplatform-mid {
    font-size: 14px !important; }

  .price-mid-p {
    font-size: 13px !important; }

  .cont-mid-p {
    width: 67.5% !important; }

  .mobile-ntf .m-ntf-header {
    font-size: 33px; }
  .mobile-ntf .m-ntf-p-lg {
    font-size: 15px;
    margin-top: 30px; }
  .mobile-ntf .m-ntf-p {
    font-size: 13px;
    margin-top: 30px; }
  .mobile-ntf .m-ntf-sub-header {
    font-size: 24px;
    margin-top: 30px; }
  .mobile-ntf .cont-mid-p {
    width: 100% !important;
    font-size: 12px !important; }
  .mobile-ntf .ntf-list-text {
    padding-left: 0px !important;
    height: auto !important; }
    .mobile-ntf .ntf-list-text img {
      position: relative !important;
      height: 250px; }
    .mobile-ntf .ntf-list-text h3 {
      font-size: 15px !important;
      margin-top: 0px !important; }
    .mobile-ntf .ntf-list-text p {
      font-size: 11px !important; }

  .socialMenu {
    display: block !important; }

  .login-b {
    position: absolute !important;
    margin-top: 0px !important;
    top: 28px;
    right: 0px;
    right: 25px; }

  .live-sup {
    display: block; }

  .langMenu {
    width: 100%;
    margin-left: 0px !important; }
    .langMenu .lang-inner {
      border: 0px solid #fff !important;
      width: 100%; }
      .langMenu .lang-inner .lang-resp {
        display: none; }
      .langMenu .lang-inner ul {
        display: table !important;
        position: relative !important;
        margin: 0 auto !important;
        width: auto !important;
        position: relative;
        box-shadow: none !important;
        top: 0px !important;
        left: 0px !important; }
        .langMenu .lang-inner ul li {
          float: left;
          border-bottom: 0px solid #fff !important; } }
@media screen and (max-width: 767px) {
  .g-checkbox i {
    font-size: 11px; }

  .gridSelect {
    grid-template-columns: 32.5% 67.5%; }

  .select2-container .select2-selection--single .select2-selection__rendered {
    padding-right: 7px; }

  .select2-container--default .select2-selection--single .select2-selection__arrow {
    width: 15px; }

  .select2-selection__rendered span {
    font-size: 12px; }

  .select2-selection__rendered span img {
    width: 35px;
    height: 22px;
    margin-right: -3px; }

  .mobile-ntf .img-responsive {
    width: 50%; }

  .footer .prpolicy-footer {
    left: 15px !important; }
  .footer .socialMenu .socialMenu-inner ul li a i {
    color: #fff; }
  .footer .socialMenu .socialMenu-inner ul li:nth-child(1) {
    background: #3b5998; }
  .footer .socialMenu .socialMenu-inner ul li:nth-child(2) {
    background: #1da1f2; }
  .footer .socialMenu .socialMenu-inner ul li:nth-child(3) {
    background: #66cdaa; }

  .fBaslik {
    text-align: center; }

  .socialMenu {
    display: block !important; }

  .login-b {
    position: absolute !important;
    margin-top: 0px !important;
    top: 28px;
    right: 0px;
    right: 25px; }

  .live-sup {
    display: block; }

  .langMenu {
    width: 100%;
    margin-left: 0px !important; }
    .langMenu .lang-inner {
      border: 0px solid #fff !important;
      width: 100%; }
      .langMenu .lang-inner .lang-resp {
        display: none; }
      .langMenu .lang-inner ul {
        display: table !important;
        position: relative !important;
        margin: 0 auto !important;
        width: auto !important;
        position: relative;
        box-shadow: none !important;
        top: 0px !important;
        left: 0px !important; }
        .langMenu .lang-inner ul li {
          float: left;
          border-bottom: 0px solid #fff !important; }

  .slider-lg {
    padding-top: 60px;
    padding-bottom: 0px;
    background-size: 100vw 65vh; }
    .slider-lg .slText {
      font-size: 13px; }
    .slider-lg h1 {
      font-size: 24px;
      font-weight: 100; }
      .slider-lg h1 strong {
        font-size: 44px;
        font-weight: 500; }
    .slider-lg .w-button {
      padding: 8px 10px; }
      .slider-lg .w-button a {
        font-size: 12px; }
    .slider-lg .g-button {
      padding: 10px 20px; }
      .slider-lg .g-button a {
        font-size: 12px; }

  .how-works .how-works-header {
    margin-top: 30px !important;
    font-size: 17px !important;
    text-align: center !important; }
  .how-works .how-works-p {
    font-size: 11px !important; }
  .how-works .img-responsive {
    width: 50%;
    margin: 0 auto; }
  .how-works .g-button {
    padding: 10px 20px;
    width: 100%;
    text-align: center; }
    .how-works .g-button::before {
      left: 120px; }
    .how-works .g-button a {
      font-size: 11px; }
  .how-works .g-dotted-bbutton-right .midText i {
    font-size: 10px; }
    .how-works .g-dotted-bbutton-right .midText i strong {
      font-size: 10px; }

  .send-again {
    margin-top: 60px !important; }

  .parPage {
    padding-top: 60px; }
    .parPage .pp-header {
      font-size: 32px !important; }
    .parPage .pp-par {
      font-size: 11px; }

  .form-header {
    font-size: 18px;
    margin-top: 15px; }

  .form-info {
    font-size: 12px;
    padding: 0px 30px; }

  .slider {
    padding-top: 75px;
    background-size: 100vw 75vh; }
    .slider h1 {
      font-size: 18px; }
      .slider h1 strong {
        font-size: 27px;
        margin-bottom: 10px !important; }
    .slider .slText {
      font-size: 12px;
      margin: 15px 0px; }
    .slider .g-list ul {
      margin-bottom: 15px; }
    .slider li {
      font-size: 12px !important; }
    .slider .w-button {
      padding: 6px 15px; }
      .slider .w-button a {
        font-size: 12px; }
    .slider .g-button {
      padding: 8px 15px; }
      .slider .g-button a {
        font-size: 12px; }
    .slider .slImgText p {
      font-size: 12px; }
    .slider .slImgText img {
      margin-top: 15px; }

  .works .works-header {
    margin: 0px; }
  .works .works-mid-p {
    width: 100% !important;
    font-size: 14px !important; }
  .works ul li {
    margin-top: 30px !important; }
  .works .works-list h3 {
    font-size: 15px; }
  .works .works-list p {
    font-size: 10px; }

  .features .f-header {
    margin: 0px; }
  .features .features-mid-p {
    width: 100%;
    font-size: 14px !important; }
  .features .fList h3 {
    text-align: center; }
  .features .fList {
    padding: 0px 30px; }

  .mplatform-mid {
    width: 100% !important;
    font-size: 12px; }

  .mplatform .imgBg img {
    display: table;
    margin: 0 auto; }

  .question .q-header {
    font-size: 25px; }
  .question .q-mid-p {
    width: 100% !important;
    font-size: 13px; }

  .pricing .brfull {
    width: 100%;
    margin-top: 15px;
    text-align: center; }
    .pricing .brfull:first-child {
      margin-top: -30px; }
    .pricing .brfull a {
      text-align: center; }
  .pricing .price-mid-p {
    width: 100% !important; }
  .pricing .brfull:last-child {
    margin-left: 1px; }
  .pricing .brfull:first-child {
    margin-right: 1px; }

  .mobile-ntf {
    padding-top: 60px; }
    .mobile-ntf .ntf-body {
      margin-bottom: 0px !important; }
    .mobile-ntf .ntf-inner {
      margin-top: 0px !important; }
    .mobile-ntf .m-ntf-header {
      font-size: 26px; }
    .mobile-ntf .m-ntf-p-lg {
      font-size: 16px;
      margin-top: 30px; }
    .mobile-ntf .m-ntf-p {
      font-size: 14px;
      margin-top: 30px; }
    .mobile-ntf .m-ntf-sub-header {
      font-size: 16px;
      margin-top: 30px; }
    .mobile-ntf .cont-mid-p {
      width: 100% !important;
      font-size: 12px !important; }
    .mobile-ntf .ntf-list-text {
      padding-left: 130px !important;
      height: 250px !important;
      margin-top: 0px; }
      .mobile-ntf .ntf-list-text .ptop3o {
        padding-top: 0px !important; }
      .mobile-ntf .ntf-list-text img {
        position: absolute !important;
        height: auto !important; }
      .mobile-ntf .ntf-list-text h3 {
        font-size: 15px !important;
        margin-top: 60px !important; }
      .mobile-ntf .ntf-list-text p {
        font-size: 12px !important; }

  .our-content .cont-header {
    font-size: 35px; }
  .our-content .cont-mid-p {
    width: 100% !important;
    font-size: 12px; }
  .our-content .socialInfo h3 {
    font-size: 16px; }
  .our-content .socialInfo p {
    font-size: 10px; }
  .our-content .socialInfo .user-text {
    margin-top: 5px; }
    .our-content .socialInfo .user-text i, .our-content .socialInfo .user-text span {
      font-size: 9px !important; }
    .our-content .socialInfo .user-text div {
      margin-left: 5px; }
  .our-content .socialInfo .share-icons {
    margin-top: 20px; }
    .our-content .socialInfo .share-icons .share-text {
      font-size: 8px;
      line-height: 2.5 !important; }
    .our-content .socialInfo .share-icons li {
      width: 20px;
      height: 20px; }
    .our-content .socialInfo .share-icons .share-icon {
      width: 20px !important;
      height: 20px !important; }
      .our-content .socialInfo .share-icons .share-icon i {
        line-height: 20px !important;
        font-size: 12px; } }
@media screen and (max-width: 1000px) {
  .hp-button {
    margin-left: 0px;
    margin-top: 0px; }

  .slider {
    height: auto; } }
@media screen and (max-width: 992px) {
  .copyright {
    text-align: right !important; }

  .prpolicy-menu {
    display: block !important; }

  .hw-head {
    display: table;
    margin: 0 auto;
    float: none !important; }

  .mplatform .slimg {
    display: table;
    margin: 30 auto; }

  .slImgText p {
    left: 0px;
    top: 500px; }

  .pricing .hidden-xs {
    display: none; }

  .how-works-header {
    text-align: center !important; }

  .how-works .img-responsive {
    width: 50%;
    margin: 0 auto; }
  .how-works .g-dotted-bbutton-right {
    height: 200px;
    display: block;
    margin: 30 auto;
    display: table; }
  .how-works .g-button {
    margin-right: 0px;
    float: left;
    position: initial; }
    .how-works .g-button::before {
      width: 1px;
      height: 70px;
      top: 60px;
      left: 50%;
      border-left: 3px dotted #e8e8e8; }
  .how-works .midText {
    position: absolute;
    float: left;
    left: 0px;
    top: 150px; }

  .menu {
    float: none;
    position: fixed;
    width: 100%;
    background: #fff;
    height: 100vh;
    left: 0px;
    top: 0px;
    height: 100vh;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: transform 0.5s ease-in-out;
    -webkit-transition: transform 0.5s ease-in-out;
    -moz-transition: transform 0.5s ease-in-out;
    -o-transition: transform 0.5s ease-in-out;
    -ms-transition: transform 0.5s ease-in-out;
    overflow-y: scroll; }
    .menu .closeArea {
      display: inline-block !important;
      background: #fff;
      position: relative;
      border-bottom: 1px solid #ececec;
      padding-bottom: 30px; }
      .menu .closeArea img:hover {
        cursor: pointer; }
      .menu .closeArea .vAlignMid {
        margin-top: 0px !important;
        margin-bottom: 0px; }
      .menu .closeArea .closeBtn2 {
        width: 40px;
        height: 40px;
        padding: 30px 5px 5px 5px !important;
        float: left;
        margin-left: 25px; }
    .menu .login-b {
      border: 1px solid #3b3b3b;
      border-radius: 5px;
      padding: 4px 15px;
      font-family: osbold;
      position: relative;
      margin-top: 30px;
      text-decoration: none;
      font-size: 0.813em;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.15;
      letter-spacing: normal;
      text-align: right;
      margin-left: 10px; }
      .menu .login-b:hover {
        background: #3b3b3b; }
        .menu .login-b:hover a {
          color: #fff; }
      .menu .login-b a {
        color: #3b3b3b; }
    .menu .login-u {
      position: absolute !important;
      top: -30px;
      height: auto !important;
      right: 25px;
      padding-top: 30px;
      display: table;
      margin: 30 auto !important;
      height: 91px; }
      .menu .login-u .user-img {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.26);
        float: left !important;
        display: table;
        margin-right: 5px;
        width: 35px;
        height: 35px; }
      .menu .login-u .img-text {
        float: none !important;
        display: none;
        margin-left: 5px !important; }
        .menu .login-u .img-text i {
          margin-left: 5px;
          font-weight: 600; }
      .menu .login-u .u-acilir {
        position: absolute !important;
        top: 70px !important;
        width: 225px; }
        .menu .login-u .u-acilir ul {
          padding: 0px !important;
          margin: 0px; }
          .menu .login-u .u-acilir ul li {
            list-style-type: none;
            padding: 11.2px 20px;
            border-bottom: 1px solid #f2f2f2; }
            .menu .login-u .u-acilir ul li:hover {
              background: #faf6eb; }
            .menu .login-u .u-acilir ul li .flRight {
              color: #878787;
              font-family: roboto;
              font-weight: normal;
              font-size: 0.875em; }
    .menu .acilir .acilir-inner {
      width: 100%;
      position: relative !important;
      height: auto;
      display: none;
      box-shadow: none !important; }
      .menu .acilir .acilir-inner ul {
        width: 100%;
        margin-top: 0px !important; }
        .menu .acilir .acilir-inner ul li {
          float: none;
          width: 100%;
          height: auto !important;
          padding: 6px !important;
          border: none !important;
          margin-top: 0px;
          margin-left: 0px !important; }
          .menu .acilir .acilir-inner ul li a {
            margin-top: 10px;
            color: #3b3b3b; }
          .menu .acilir .acilir-inner ul li img {
            display: none; }
    .menu .activeLink {
      color: #fff; }
    .menu ul {
      padding: 0px;
      width: 100%;
      background: #fff; }
      .menu ul .menu-list {
        float: none !important;
        width: 100% !important;
        list-style-type: none;
        height: auto !important;
        padding: 15px !important;
        border-bottom: 1px solid #f2f2f2;
        text-align: center; }
        .menu ul .menu-list a {
          font-family: osbold;
          position: relative;
          text-decoration: none;
          font-size: 0.813em;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.15;
          letter-spacing: normal;
          text-align: right;
          color: #3b3b3b; }
          .menu ul .menu-list a:hover {
            color: #fff; }
          .menu ul .menu-list a i {
            color: #3b3b3b;
            margin-left: 2px; }

  .how-works-p {
    text-align: center !important; }

  .hambmenu {
    display: block !important;
    margin-left: 0px !important; }

  .logo {
    position: absolute;
    left: -webkit-calc(50% - 55px);
    left: -moz-calc(50% - 55px);
    left: -ms-calc(50% - 55px);
    left: -o-calc(50% - 55px);
    left: calc(50% - 55px);
    width: 100px;
    height: 40px;
    top: 5px; }
    .logo img:last-child {
      height: 25px; }

  .header {
    height: 60px; }
    .header .logo {
      padding-top: 10px; }
    .header .hambmenu {
      margin-top: 10px; }

  .hambmenu {
    width: 20px;
    height: 45px;
    padding: 5px;
    margin-top: 25px;
    margin-left: 200px;
    display: none;
    float: left; }
    .hambmenu .line {
      width: 100%;
      height: 3px;
      background: #3b3b3b;
      margin-top: 6px; } }
@media screen and (max-width: 768px) {
  .hw-head {
    display: table;
    margin: 0 auto;
    float: none !important; }

  .how-works-header {
    text-align: center !important; }

  .how-works-p {
    text-align: center !important; }

  .fBaslik {
    text-align: center !important; }

  .slImgText p {
    left: 0px;
    top: 515px; }

  .pricing .hidden-xs {
    display: none; }

  .features h4, .features p {
    text-align: center; }

  .features img {
    display: table;
    margin: 30 auto; }

  .img-mid-resp {
    display: table;
    margin: 0 auto; }

  .signup-inner {
    padding-left: 0px !important;
    padding-right: 0px !important;
    border-right: none !important; }

  .signup-why {
    padding-left: 0px !important;
    padding-right: 0px !important; }

  .hambmenu {
    display: block !important;
    margin-left: 0px !important; }

  .logo {
    position: absolute;
    left: -webkit-calc(50% - 55px);
    left: -moz-calc(50% - 55px);
    left: -ms-calc(50% - 55px);
    left: -o-calc(50% - 55px);
    left: calc(50% - 55px);
    width: 100px;
    height: 40px;
    top: 5px; }
    .logo img:last-child {
      height: 25px; }

  .header {
    height: 60px; }
    .header .logo {
      padding-top: 10px; }
    .header .hambmenu {
      margin-top: 10px; }

  .c-gsm-header {
    font-size: 23px !important; }

  .c-gsm-info {
    font-size: 10px !important; }

  .hambmenu {
    width: 20px;
    height: 45px;
    padding: 5px;
    margin-top: 25px;
    margin-left: 200px;
    display: none;
    float: left; }
    .hambmenu .line {
      width: 100%;
      height: 3px;
      background: #3b3b3b;
      margin-top: 6px; }

  .menu {
    float: none;
    position: fixed;
    width: 100%;
    background: #fff;
    height: 100vh;
    left: 0px;
    top: 0px;
    height: 100vh;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
    transition: transform 0.5s ease-in-out;
    -webkit-transition: transform 0.5s ease-in-out;
    -moz-transition: transform 0.5s ease-in-out;
    -o-transition: transform 0.5s ease-in-out;
    -ms-transition: transform 0.5s ease-in-out;
    overflow-y: scroll; }
    .menu .closeArea {
      display: inline-block !important;
      background: #fff;
      position: relative;
      border-bottom: 1px solid #ececec;
      padding-bottom: 30px; }
      .menu .closeArea img:hover {
        cursor: pointer; }
      .menu .closeArea .vAlignMid {
        margin-top: 0px !important;
        margin-bottom: 0px; }
      .menu .closeArea .closeBtn2 {
        width: 40px;
        height: 40px;
        padding: 37px 5px 5px 5px !important;
        float: left;
        margin-left: 25px; }
    .menu .login-b {
      border: 1px solid #3b3b3b;
      border-radius: 5px;
      padding: 4px 15px;
      font-family: osbold;
      position: relative;
      margin-top: 30px;
      text-decoration: none;
      font-size: 0.813em;
      font-weight: 500;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.15;
      letter-spacing: normal;
      text-align: right;
      margin-left: 10px; }
      .menu .login-b:hover {
        background: #3b3b3b; }
        .menu .login-b:hover a {
          color: #fff; }
      .menu .login-b a {
        color: #3b3b3b; }
    .menu .login-u {
      position: absolute !important;
      top: -30px;
      height: auto !important;
      right: 25px;
      padding-top: 30px;
      display: table;
      margin: 30 auto !important;
      height: 91px; }
      .menu .login-u .user-img {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.26);
        float: left !important;
        display: table;
        margin-right: 5px;
        width: 35px;
        height: 35px; }
      .menu .login-u .img-text {
        float: none !important;
        display: none;
        margin-left: 5px !important; }
        .menu .login-u .img-text i {
          margin-left: 5px;
          font-weight: 600; }
      .menu .login-u .u-acilir {
        position: absolute !important;
        top: 70px !important;
        width: 225px; }
        .menu .login-u .u-acilir ul {
          padding: 0px !important;
          margin: 0px; }
          .menu .login-u .u-acilir ul li {
            list-style-type: none;
            padding: 11.2px 20px;
            border-bottom: 1px solid #f2f2f2; }
            .menu .login-u .u-acilir ul li:hover {
              background: #faf6eb; }
            .menu .login-u .u-acilir ul li .flRight {
              color: #878787;
              font-family: roboto;
              font-weight: normal;
              font-size: 0.875em; }
    .menu .acilir .acilir-inner {
      width: 100%;
      position: relative !important;
      height: auto;
      display: none;
      box-shadow: none !important; }
      .menu .acilir .acilir-inner ul {
        width: 100%;
        margin-top: 0px !important; }
        .menu .acilir .acilir-inner ul li {
          float: none;
          width: 100% !important;
          height: auto !important;
          padding: 6px !important;
          border: none !important;
          margin-top: 0px;
          margin-left: 0px !important; }
          .menu .acilir .acilir-inner ul li a {
            margin-top: 10px;
            color: #3b3b3b; }
          .menu .acilir .acilir-inner ul li img {
            display: none; }
    .menu .activeLink {
      color: #fff; }
    .menu ul {
      padding: 0px;
      width: 100%;
      background: #fff; }
      .menu ul .menu-list {
        float: none !important;
        width: 100% !important;
        list-style-type: none;
        height: auto !important;
        padding: 15px !important;
        border-bottom: 1px solid #f2f2f2;
        text-align: center; }
        .menu ul .menu-list a {
          font-family: osbold;
          position: relative;
          text-decoration: none;
          font-size: 0.813em;
          font-weight: 500;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.15;
          letter-spacing: normal;
          text-align: right;
          color: #3b3b3b; }
          .menu ul .menu-list a:hover {
            color: #fff; }
          .menu ul .menu-list a i {
            color: #3b3b3b;
            margin-left: 2px; } }
@media screen and (max-width: 380px) {
  .sure-inp {
    width: 100%; }

  .confGsm .g-i-button {
    width: 100%; } }
@media screen and (max-width: 436px) {
  .cal-input {
    left: 0px;
    top: -90px !important; }

  .chart-info {
    margin-top: 105px !important; } }

/*# sourceMappingURL=style.css.map */
